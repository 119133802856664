export const awards = [
  {
    id: 4,
    src: '/Assets/awards/award_4.png',
    deeplink: 'https://www.ashoka.org/en-in/fellow/akanksha-hazari',
  },
  {
    id: 5,
    src: '/Assets/awards/award_5.png',
    deeplink: 'https://www.livemint.com/Opinion/zDZJk4yg9sievul6FRv9IJ/We-hope-to-become-a-national-player-Akanksha-Hazari.html',
  },
  {
    id: 6,
    src: '/Assets/awards/award_6.png',
    deeplink: 'https://www.financialexpress.com/industry/sme/indian-rewards-startup-in-googles-launchpad-studio-heres-how-m-paani-is-using-ai-to-help-small-retailers/1304692/',
  },
  {
    id: 7,
    src: '/Assets/awards/award_7.png',
    deeplink: 'https://yourstory.com/2018/05/innovation-change-drive-akanksha-hazaris-vision-m-paani/',
  },
  {
    id: 8,
    src: '/Assets/awards/award_8.png',
    deeplink: 'https://www.livemint.com/Companies/SnFK7sOW53r2uxUK8fOS3K/MPaani-raises-135-million-in-IDG-Venturesled-funding-roun.html',
  },
  {
    id: 9,
    src: '/Assets/awards/award_9.png',
    deeplink: 'https://www.business-standard.com/article/technology/the-game-of-squash-gave-india-a-fine-entrepreneur-here-s-how-it-happened-118091800252_1.html',
  },
  {
    id: 10,
    src: '/Assets/awards/award_10.png',
    deeplink: 'https://www.scmp.com/lifestyle/families/article/1906468/hong-kong-indian-entrepreneur-out-build-next-alibaba-mumbai',
  },
  {
    id: 11,
    src: '/Assets/awards/award_11.png',
    deeplink: '',
  },
  {
    id: 12,
    src: '/Assets/awards/award_12.png',
    deeplink: 'https://www.youtube.com/watch?v=Ipx6_yp1Tz4',
  },
  {
    id: 13,
    src: '/Assets/awards/award_13.png',
    deeplink: '',
  },
  {
    id: 14,
    src: '/Assets/awards/award_14.png',
    deeplink: 'https://bwdisrupt.businessworld.in/article/Indian-Startup-m-Paani-Handpicked-for-Google-s-Flagship-Studio-Program/06-09-2018-159370/',
  },
  {
    id: 15,
    src: '/Assets/awards/award_15.png',
    deeplink: '',
  },
  {
    id: 16,
    src: '/Assets/awards/award_16.png',
    deeplink: '',
  },
  {
    id: 17,
    src: '/Assets/awards/award_17.png',
    deeplink: 'https://www.financialexpress.com/industry/sme/indian-rewards-startup-in-googles-launchpad-studio-heres-how-m-paani-is-using-ai-to-help-small-retailers/1304692/',
  },
  {
    id: 18,
    src: '/Assets/awards/award_18.png',
    deeplink: 'https://www.hult.edu/blog/entrepreneur-gives-loyalty-programmes-a-social-spin-dna/',
  },
  {
    id: 27,
    src: '/Assets/awards/award_27.png',
    deeplink: 'https://techcrunch.com/2018/09/05/googles-launchpad-studio-accelerator-welcomes-a-cohort-of-blockchain-and-finance-startups/',
  },
  {
    id: 28,
    src: '/Assets/awards/award_28.png',
    deeplink: 'https://economictimes.indiatimes.com/small-biz/money/m-paani-raises-1-35-million-in-pre-series-a-round/articleshow/57662720.cms',
  },
  {
    id: 30,
    src: '/Assets/awards/award_30.png',
    deeplink: 'https://www.vitalvoices.org/people/akanksha-hazari-2/',
  },
];

export const funding = [
  {
    id: 1,
    src: '/Assets/funding/funding_1.png',
    deeplink: 'https://analyticsindiamag.com/m-paani-raises-5-5-million-series-funding/',
  },
  {
    id: 2,
    src: '/Assets/funding/funding_2.png',
    deeplink: 'https://e27.co/todays-top-tech-news-gojek-co-founder-invests-in-indias-m-paani-akulaku-is-eyeing-offshore-financing-20191212/',
  },
  {
    id: 3,
    src: '/Assets/funding/funding_3.png',
    deeplink: 'https://www.mpaani.com/wp-content/uploads/2019/12/ET-m.Paani-lands-5.5-million-in-Series-A.jpeg',
  },
  {
    id: 4,
    src: '/Assets/funding/funding_4.jpg',
    deeplink: 'https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/m-paani-lands-5-5-million-in-series-a/articleshow/72466403.cms',
  },
  {
    id: 5,
    src: '/Assets/funding/funding_5.jpg',
    deeplink: 'https://tech.economictimes.indiatimes.com/news/startups/m-paani-raises-5-5-million-in-series-a-funding/72465800',
  },
  {
    id: 6,
    src: '/Assets/funding/funding_6.png',
    deeplink: 'https://developers.googleblog.com/2019/12/mpaani-raises-series-from-connections.html',
  },
  {
    id: 7,
    src: '/Assets/funding/funding_7.png',
    deeplink: 'https://hrnxt.com/news/investment/m-paani-raises-5-5-million-in-series-a-funding-round/10162/2019/12/12/',
  },
  {
    id: 8,
    src: '/Assets/funding/funding_8.jpg',
    deeplink: 'https://inc42.com/buzz/google-launchpad-startup-m-paani-raises-5-5-mn-series-a-funding/',
  },
  {
    id: 9,
    src: '/Assets/funding/funding_9.png',
    deeplink: 'https://www.mpaani.com/wp-content/uploads/2019/12/Mint-1-1.jpeg',
  },
  {
    id: 10,
    src: '/Assets/funding/funding_10.png',
    deeplink: 'https://www.mpaani.com/wp-content/uploads/2019/12/Newsband_m.Paani_-1.jpeg',
  },
  {
    id: 11,
    src: '/Assets/funding/funding_11.png',
    deeplink: 'https://www.webwire.com/ViewPressRel.asp?aId=251579',
  },
  {
    id: 12,
    src: '/Assets/funding/funding_12.jpg',
    deeplink: 'https://www.thenewsminute.com/article/mpaani-raises-55-mn-series-led-ac-ventures-henkel-and-others-114004',
  },
  {
    id: 13,
    src: '/Assets/funding/funding_13.png',
    deeplink: 'https://www.vitalvoices.org/2019/12/m-paani-raises-5-5million/',
  },
];

export const testimonials = [
  {
    id: 1,
    author: 'Rajesh Dedhia, Kamal Stores',
    content: 'LoveLocal\'s platform has added great value to the business by providing us with a platform to launch our online store. In tough situations like this when customers are avoiding to step out & sound physical contact, customers are placing orders on our online store',
  },
  {
    id: 2,
    author: 'Jinesh Gala, Pragati Supermarket',
    content: 'I am glad to have my online store on the LoveLocal platform. I see 2x growth in my footfall and business. Customers love the convenience of home deliveries and easy to use features of the app like call to order.',
  },
  {
    id: 3,
    author: 'Sanjay Parmar, Ninety Degrees',
    content: 'Taking my store online was very easy with LoveLocal & see great value in the product',
  },
  {
    id: 4,
    author: 'Grocery Shop, Thane',
    content: 'I partnered with them for my store launch. Within few days they set up the entire store and did an excellent marketing campaign to my store',
  },
  {
    id: 5,
    author: 'Supermarket, Thakurli',
    content: 'Now I am able to upload all my products and offers on the LoveLocal app and share it with my customer through WhatsApp and SMS',
  },
  {
    id: 6,
    author: 'Grocery Shop, Dombivilli',
    content: 'Over the period of 2 years with LoveLocal, I have got more than 15% growth in business and now my customers do all their shopping at my store',
  },
];

export const benefits = [
  {
    id: 1,
    src: '/Assets/benefits/transactions.svg',
    heading: 'Upto 25%',
    subHeading: 'Number of Transactions',
  },
  {
    id: 2,
    src: '/Assets/benefits/customer_base.svg',
    heading: 'Upto 20%',
    subHeading: 'Customer Base',
  },
  {
    id: 3,
    src: '/Assets/benefits/revenue.svg',
    heading: 'Upto 30%',
    subHeading: 'Business Revenue',
  },
  {
    id: 4,
    src: '/Assets/benefits/customer.svg',
    heading: 'Upto 60%',
    subHeading: 'Customer Retention',
  },
];

export const muchMore = [
  {
    id: 1,
    src: '/Assets/much-more/analytics.svg',
    content: 'Consultation & Planning from our Retail Experts on how to modernize your customer relationships',
    contentHindi: 'अपने ग्राहक संबंधों को आधुनिक बनाने के लिए हमारे रिटेल विशेषज्ञों से परामर्श और योजना ले',
  },
  {
    id: 2,
    src: '/Assets/much-more/support.svg',
    content: 'Retailer Success Support Center available 12 hours a day, 7 days a week by phone & WhatsApp',
    contentHindi: 'रिटेलर सक्सेस सपोर्ट सेंटर एक दिन में 12 घंटे, सप्ताह में 7 दिन फोन और व्हाट्सएप द्वारा उपलब्ध है',
  },
  {
    id: 3,
    src: '/Assets/much-more/consumer_support.svg',
    content: 'Shopper Support—including free physical delivery, call center, and chat support',
    contentHindi: 'शॉपर सपोर्ट-जिसमें निःशुल्क शारीरिक वितरण, कॉल सेंटर और चैट समर्थन शामिल हैं',
  },
  {
    id: 4,
    src: '/Assets/much-more/staff.svg',
    content: 'Free trainings for your staff, with optional staff incentive programs',
    contentHindi: 'अपने कर्मचारियों के लिए मुफ्त प्रशिक्षण, वैकल्पिक कर्मचारी प्रोत्साहन कार्यक्रम के साथ',
  },
];

export const LoveLocal = {
  lovelocal: {
    href: 'https://lovelocal.in',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  career: {
    href: 'mailto: dream@lovelocal.in',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  press: {
    href: 'mailto: media@lovelocal.in',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  partnership: {
    href: 'mailto: partner@lovelocal.in',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  twitter: {
    href: 'https://twitter.com/lovelocalindia/',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  facebook: {
    href: 'https://www.facebook.com/lovelocalindia/',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  insta: {
    href: 'https://instagram.com/lovelocalindia/',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  linkedin: {
    href: 'https://www.linkedin.com/company/m-paani/',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  playstore: {
    href: 'https://play.google.com/store/apps/details?id=chotelal.mpaani.com.android.chotelal',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  youtube: {
    href: 'https://www.youtube.com/channel/UCqs6-xP0gZZZBtaED7DssZg/',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  tnc: {
    href: '/member/terms/',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  privacyPolicy: {
    href: '/privacy-policy/',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  contactUs: {
    href: 'mailto: cs@lovelocal.in',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
};
