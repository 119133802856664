// import { CDNURLMappings } from './Config';
import {
  getToken, getProfile, getAddress,
} from './Storage';

export const isAuthenticated = () => !!getToken(); // if user is logged in

export const isAlreadyLoggedIn = (dispatchLogin) => {
  const profile = getProfile();
  const address = getAddress();
  if (!profile || profile.isDummy || !address || address.isDummy || !getToken()) {
    if (dispatchLogin) {
      dispatchLogin();
    }
    return false;
  }
  return true;
};

export const isGuestUser = () => {
  const profile = getProfile();
  if (!profile || profile.isDummy || !getToken()) {
    return true;
  }
  return false;
};

const joinText = (textElement, withDelimeter = true) => {
  const text = textElement ? textElement.trim() : '';
  if (text === '') {
    return '';
  }
  return `${text}${
    withDelimeter ? ', ' : ''
  }`;
};

export const getInitials = (text) => {
  const initials = text.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export const getAddressString = (address = getAddress()) => {
  if (address) {
    const {
      flatNumber, buildingName, streetName,
      localityName, landmark, addressText, googleAddress,
    } = address;
    return `${
      joinText(flatNumber)
    }${
      joinText(buildingName)
    }${
      joinText(streetName)
    }${
      joinText(localityName)
    }${
      joinText(landmark)
    }${
      addressText
        ? joinText(addressText, false)
        : joinText(googleAddress, false)
    }`;
  }
  return address ? address.addressText : '';
};

export const shortDateOptions = { year: 'numeric', month: 'short', day: '2-digit' };

export const longDateOptions = { year: 'numeric', month: 'long', day: '2-digit' };

export const fullDateOptions = {
  year: 'numeric', month: 'long', day: '2-digit', weekday: 'short',
};

export const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
export const convert24HrTo12Hr = (timeString) => {
  const parts = timeString.split(':', 2);
  const hour = Number(parts[0]);
  const minutes = Number(parts[1]);
  const time = `${hour % 12 || 12}${minutes === 0 ? '' : ':'}${minutes < 10 && minutes > 0 ? `0${minutes}` : minutes || ''}`;
  return hour > 11 ? `${time} PM` : `${time} AM`;
};

const convertSingleTo2Digit = (d) => (d < 10 ? `0${d}` : `${d}`);

export const getDiffTimeInHHMMSS = (time) => {
  try {
    const date = new Date(time);
    // subtract 5:30 here becoz of epoch is respective to 1 Jan 1970 5:30AM
    return `
      ${convertSingleTo2Digit(date.getHours() - 5)} :
      ${convertSingleTo2Digit(date.getMinutes() - 30)} :
      ${convertSingleTo2Digit(date.getSeconds())}`;
  } catch {
    return '';
  }
};

export const copyToClipboard = (text) => {
  const element = document.createElement('input');
  document.body.append(element);
  element.setAttribute('value', text);
  element.select();
  document.execCommand('copy');
  element.remove();
};

export const getDeliveryTimeString = (timeInMin) => {
  let time = timeInMin;
  const days = Math.floor(time / (24 * 60));
  time %= (24 * 60);
  const hrs = Math.floor(time / 60);
  const min = time % 60;
  return `${
    // eslint-disable-next-line no-nested-ternary
    days < 1
      ? ''
      : days === 1
        ? `${days} Day`
        : `${days} Days`
  } ${
    // eslint-disable-next-line no-nested-ternary
    hrs < 1
      ? ''
      : hrs === 1
        ? `${hrs} Hour`
        : `${hrs} Hours`
  } ${
    // eslint-disable-next-line no-nested-ternary
    min < 1
      ? ''
      : min === 1
        ? `${min} Min`
        : `${min} Mins`
  }`;
};

export function getHoursDifference(date1, date2) {
  return Math.floor((date1.getTime() - date2.getTime()) / (1000 * 3600));
}

export function getDateDifference(date1, date2) {
  return Math.floor((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
}

export function getTimeElapsedInSec(time) {
  return `${Math.round((Date.now() - time) / 1000)} sec`;
}

export function getDateLocaleString(date) {
  return date ? new Date(date).toLocaleString() : new Date().toLocaleString();
}

export function appendDateSuffix(number) {
  const parts = number.toString().split();
  switch (parts[parts.length - 1]) {
    case '1':
      return `${number}st`;
    case '2':
      return `${number}nd`;
    case '3':
      return `${number}rd`;
    default:
      return `${number}th`;
  }
}

export function rewardsValidity(validFrom, validTill) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const startDate = new Date(validFrom);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(validTill);
  endDate.setHours(0, 0, 0, 0);
  const startDateParts = new Date(validFrom).toDateString().split(' ');
  const endDateParts = new Date(validTill).toDateString().split(' ');
  if (today.getTime() === endDate.getTime()) {
    return {
      expiringToday: true,
    };
  }
  if ((endDate.getTime() - today.getTime()) === 1) {
    return {
      expiringTomorrow: true,
    };
  }
  if (startDate.getTime() === endDate.getTime()) {
    return {
      date: `${startDateParts[2]} ${startDateParts[1]}`,
    };
  }
  return {
    dateRange: `${startDateParts[2]} ${startDateParts[1]} - ${
      endDateParts[2]} ${endDateParts[1]}`,
  };
}

export function rewardsExpireOn(validTill) {
  const dateParts = new Date(validTill).toDateString().split(' ');
  return `${dateParts[2]} ${dateParts[1]}`;
}

// Temp To Be Removed after Revamp
export const errorImageFallback = (event, fallbackImageSrc) => {
  // eslint-disable-next-line no-param-reassign
  event.target.src = fallbackImageSrc;
};

export const getSEOFriendlyURL = (text = '') => (
  text.trim().toLowerCase()
    .replace(/[^a-zA-Z\d\s:]/g, ' ')
    .replace(/\s\s+/g, ' ')
    .replace(/ /g, '-')
);

export function getHostName() {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://www.lovelocal.in/';
    case 'release':
      return 'https://b2c-frontend-release.lovelocal.in/';
    default:
      return 'https://b2cweb-staging.mpaani.com/';
  }
}

export function getCDNImageUrl(url = '') {
  // console.log(url);
  if (!url) {
    return '';
  }
  return url;
  // let newUrl = getHostName();
  // let urlComponents = {};
  // try {
  //   urlComponents = new URL(url);
  // } catch {
  //   return url;
  // }
  // const { hostname, pathname } = urlComponents;
  // if (hostname in CDNURLMappings) {
  //   newUrl = newUrl.concat(CDNURLMappings[hostname]);
  // } else {
  //   return url;
  // }
  // newUrl = newUrl.concat(pathname);
  // return newUrl;
}

export const getCodeFromURL = (text = '') => {
  const parts = text.split('-');
  return parts[parts.length - 1];
};

export const goBack = (history) => {
  if (history && history.length > 2) {
    history.goBack();
  } else {
    history.push('/');
  }
};

export const stickersInfo = {
  positive_great_service_of_shop: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Great Service of Shop',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Service_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_great_quality_of_products: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Great Quality of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Quality_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_super_fast_delivery: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Superfast Delivery',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Delivery_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_super_fast_order_processing: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Superfast Order Processing',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Processing_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_exciting_offers: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Exciting Offers',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Offers_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_wide_variety_of_products: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Wide Variety of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Variety_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  positive_great_prices_of_products: {
    title: 'POSITIVE_FEEDBACK',
    text: 'Great Prices of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Pricing_positive.png',
    textColor: 'BLACK',
    isNegativeFeedback: false,
  },
  negative_bad_service_of_shop: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Bad Service of Shop',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Service_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_poor_quality_of_products: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Poor Quality of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Quality_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_slow_order_processing_speed: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Slow Order Processing Speed',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Processing_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_no_availability_of_offers: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'No Availability of Offers',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Offers_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_low_variety_of_products: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Low Variety of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Variety_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_high_prices_of_products: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'High Prices of Products',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Pricing_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
  negative_slow_order_delivery_speed: {
    title: 'NEGATIVE_FEEDBACK',
    text: 'Slow Order Delivery Speed',
    url: 'https://love-local.s3.ap-south-1.amazonaws.com/images/review_stickers/Delivery_negative.png',
    textColor: 'RED',
    isNegativeFeedback: true,
  },
};

export const LOVELOCAL = {
  phone: '8433991506',
  address: 'WeWork, 1st Floor, Commerz II,Oberoi Garden City, Goregaon East, Mumbai, MH-400063',
  career: 'dream@lovelocal.in',
  contactUs: 'cs@lovelocal.in',
  partnership: 'partner@lovelocal.in',
  supportEmail: 'support@lovelocal.in',
  twitter: 'https://twitter.com/lovelocalindia/',
  facebook: 'https://www.facebook.com/lovelocalindia/',
  insta: 'https://instagram.com/lovelocalindia/',
  linkedin: 'https://www.linkedin.com/company/m-paani/',
  playstore: 'https://lovelocal.sng.link/Dg5ou/vm3b',
  appstore: 'https://apps.apple.com/in/app/lovelocal-buy-online-grocery/id6443932475',
  partnerPlayStore: 'https://lovelocal.sng.link/Dgbj1/l3mo',
  youtube: 'https://www.youtube.com/channel/UCqs6-xP0gZZZBtaED7DssZg/',
  tnc: '/member/terms/',
  privacyPolicy: '/privacy-policy/',
  becomeShop: '/',
  aboutUs: '/about',
  blog: 'https://www.lovelocal.in/blog',
  googleLocation: 'https://www.google.com/maps/place/m.Paani/@18.9872348,72.8239275,15z/data=!4m5!3m4!1s0x0:0x66fea794948a9815!8m2!3d18.9872348!4d72.8239275',
  support: '07948060679',
  consumerAppDeeplink: 'https://lovelocal.page.link/lovelocal-daily-needs',
  referShopForm: 'https://docs.google.com/forms/d/e/1FAIpQLSfeA8bQf5wDUtEmbDGjj-Yw1i9G8fZ0KbDf-xP8wjSiUdz_Aw/viewform',
  productPlaceholder: 'https://storage.googleapis.com/homedelivery-product-images/Placeholder(1).png',
  categoryPlaceholder: 'https://storetypeimages.s3.ap-south-1.amazonaws.com/Genric-Product.png',
};

const info = {
  title: '',
  backendValue: '',
  pathValue: '',
};

export const countryMappingInfo = (country = '') => {
  switch (country) {
    case 'india':
      return {
        ...info,
        ...{
          title: 'India',
          backendValue: 'india',
          pathValue: 'india',
        },
      };

    default:
      return info;
  }
};

export const cityMappingInfo = (city = '') => ({
  ...info,
  ...{
    title: city.charAt(0).toUpperCase() + city.slice(1),
    backendValue: city,
    pathValue: city,
  },
});

export const localityMappingInfo = (locality = '') => ({
  ...info,
  ...{
    title: locality.charAt(0).toUpperCase() + locality.slice(1),
    backendValue: locality,
    pathValue: locality,
  },
});

export const popularLocalities = [
  {
    title: 'Malad West',
    urlPath: '/india/mumbai/malad-west',
  },
  {
    title: 'Andheri East',
    urlPath: '/india/mumbai/andheri-east',
  },
  {
    title: 'Andheri West',
    urlPath: '/india/mumbai/andheri-west',
  },
  {
    title: 'Kurla',
    urlPath: '/india/mumbai/kurla',
  },
  {
    title: 'Mankhurd',
    urlPath: '/india/mumbai/mankhurd',
  },
  {
    title: 'Navi Mumbai',
    urlPath: '/india/new-mumbai',
  },
  {
    title: 'Hadapsar',
    urlPath: '/india/pune/hadapsar',
  },
  {
    title: 'Kandivali East',
    urlPath: '/india/mumbai/kandivali-east',
  },
  {
    title: 'Kandivali West',
    urlPath: '/india/mumbai/kandivali-west',
  },
  {
    title: 'Bandra West',
    urlPath: '/india/mumbai/bandra-west',
  },
  {
    title: 'Dwarka',
    urlPath: '/india/new-delhi/dwarka',
  },
  {
    title: 'Vaishali',
    urlPath: '/india/ghaziabad/vaishali',
  },
  {
    title: 'Lower Parel',
    urlPath: '/india/mumbai/lower-parel',
  },
  {
    title: 'Viman Nagar',
    urlPath: '/india/pune/viman-nagar',
  },
  {
    title: 'Aundh',
    urlPath: '/india/pune/aundh',
  },
  {
    title: 'Wakad',
    urlPath: '/india/pune/wakad',
  },
  {
    title: 'Kondhwa',
    urlPath: '/india/pune/kondhwa',
  },
  {
    title: 'Santacruz East',
    urlPath: '/india/mumbai/santacruz-east',
  },
  {
    title: 'Dadar',
    urlPath: '/india/mumbai/dadar',
  },
  {
    title: 'Jogeshwari West',
    urlPath: '/india/mumbai/jogeshwari-west',
  },
  {
    title: 'Borivali West',
    urlPath: '/india/mumbai/borivali-west',
  },
  {
    title: 'Dombivli East',
    urlPath: '/india/mumbai/dombivli-east',
  },
  {
    title: 'Mira Road',
    urlPath: '/india/mumbai/mira-road',
  },
  {
    title: 'Chembur',
    urlPath: '/india/mumbai/chembur',
  },
  {
    title: 'Malad East',
    urlPath: '/india/mumbai/malad-east',
  },
  {
    title: 'Thane West',
    urlPath: '/india/mumbai/thane-west',
  },
  {
    title: 'Moti Nagar',
    urlPath: '/india/new-delhi/moti-nagar',
  },
  {
    title: 'Paschim Vihar',
    urlPath: '/india/new-delhi/paschim-vihar',
  },
  {
    title: 'Nangloi',
    urlPath: '/india/new-delhi/nangloi',
  },
  {
    title: 'Sion',
    urlPath: '/india/mumbai/sion',
  },
];

export const popularCities = [
  {
    title: 'Mumbai',
    urlPath: '/india/mumbai',
  },
  {
    title: 'Pune',
    urlPath: '/india/pune',
  },
  {
    title: 'Delhi',
    urlPath: '/india/delhi',
  },
  {
    title: 'Noida',
    urlPath: '/india/noida',
  },
  {
    title: 'Gurgaon',
    urlPath: '/india/gurgaon',
  },
  {
    title: 'Ghaziabad',
    urlPath: '/india/ghaziabad',
  },
  {
    title: 'Hyderabad',
    urlPath: '/india/hyderabad',
  },
  {
    title: 'Bangalore',
    urlPath: '/india/bangalore',
  },
  {
    title: 'Ahmedabad',
    urlPath: '/india/ahmedabad',
  },
  {
    title: 'Indore',
    urlPath: '/india/indore',
  },
  {
    title: 'Jaipur',
    urlPath: '/india/jaipur',
  },
  {
    title: 'Bhopal',
    urlPath: '/india/bhopal',
  },
  {
    title: 'Ambala',
    urlPath: '/india/ambala',
  },
  {
    title: 'Lucknow',
    urlPath: '/india/lucknow',
  },
  {
    title: 'Gwalior',
    urlPath: '/india/gwalior',
  },
];

export const productCategoryMappingInfo = (category) => {
  const { categoryId = 'grains_oil_and_masala', displayName = 'Grains, Oils & Masalas' } = category;
  switch (categoryId) {
    case 'grains_oil_and_masala':
      return {
        seoMetaTitle: 'Buy Grains, Oil & Masala Online | Online Grocery Delivery from Local Shops | LoveLocal ',
        seoMetaDescription: 'Buy Grains, Oils & Masalas online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Grains, Oils, Masalas Online',
      };
    // Sub Categories of Grains Oil and Masala
    case 'sooji_rawa':
      return {
        seoMetaTitle: 'Buy Sooji & Rawa  Online | Get Sooji & Rawa Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy sooji and rawa online at LoveLocal. Avail great offers and discounts while you get your products delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Sooji, Rawa Online',
      };

    case 'sugar_salt_jaggery':
      return {
        seoMetaTitle: 'Buy Sugar, Jaggery & Salt Online | Get Sugar & Jaggery Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy sugar, jaggery & salt online at LoveLocal. Avail great offers and discounts while you get your products delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Sugar, Salt, Jaggery Online',
      };

    case 'spices_masalas':
      return {
        seoMetaTitle: 'Buy Spices, Masalas Online | Get Spices, Masalas Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy spices & masalas online at LoveLocal. Avail great offers and discounts while you get your products delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Spices, Masalas Online',
      };
    case 'edible_oils':
      return {
        seoMetaTitle: 'Buy Edible Oils Online | Get Edible Oils Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy edible oils online at LoveLocal. Avail great offers and discounts while you get your products delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Edible Oils Online',
      };
    case 'other_flours':
      return {
        seoMetaTitle: 'Buy Other Flours Online | Get Other Flours Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy other flours online at LoveLocal. Avail great offers and discounts while you get your products delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Other Flours Online',
      };
    case 'atta_flour':
      return {
        seoMetaTitle: 'Buy Atta, Flours Online | Get All Type of Flours Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy atta, flours online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Atta, Chakki Atta, Other Flours Online',
      };
    case 'all_grains_oil_masalas':
      return {
        seoMetaTitle: 'Buy All Products Online | Get All Products Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy all products online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Products Online',
      };
    case 'rice_rice_products':
      return {
        seoMetaTitle: 'Buy Rice Online | Get All Type of Rice Products Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy rice online at LoveLocal. Avail great offers and discounts while you get all type of rice delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Rice, Rice Products Online',
      };
    case 'dals_pulses':
      return {
        seoMetaTitle: 'Buy Dals and Pulses Online | Get Dals and Pulses Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy dals and pulses online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Dals and Pulses Online',
      };
    case 'ghee_vanaspati':
      return {
        seoMetaTitle: 'Buy Ghee & Vanaspati Online | Get Ghee & Vanaspati Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy ghee & vanaspati online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Ghee and Vanaspati Online',
      };

    case 'health_and_medicine':
      return {
        seoMetaTitle: 'Order Medicines Online | Buy Medicine from Local Pharmacies | LoveLocal',
        seoMetaDescription: 'Buy medicines online at LoveLocal. Avail great offers and discounts while you get your medicines delivered from chemist stores near your location. Try LoveLocal today.',
        h1: 'Buy Medicines Online',
      };
    // Sub Categories of Health & Medicine
    case 'antiseptic_liquid_cream':
      return {
        seoMetaTitle: 'Buy Antiseptic Liquid and Cream Online | Get Antiseptic Liquid and Cream Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Antiseptic Liquid and Cream online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Antiseptic Liquid and Cream Online',
      };
    case 'all_medicines':
      return {
        seoMetaTitle: 'Buy All Medicines Online | Get All Medicines Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All Medicines online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Medicines Online',
      };
    case 'speciality_supplements':
      return {
        seoMetaTitle: 'Buy Speciality Supplements Online | Get Speciality Supplementse Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Speciality Supplements online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Speciality Supplements Online',
      };

    case 'personal_care':
      return {
        seoMetaTitle: 'Buy Personal Care Products Online from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy personal care online at LoveLocal. Avail great offers and discounts while you get all type of personal care products delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Skin, Hair, Oral Care, Other Personal Care Products Online',
      };
    // Sub Categories of Personal Care
    case 'skin_care':
      return {
        seoMetaTitle: 'Buy Skin Care Online | Get Skin Care Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Skin Care online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Skin Care Online',
      };
    case 'hair_care':
      return {
        seoMetaTitle: 'Buy Hair Care Online | Get Hair Care Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Hair Care online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Hair Care Online',
      };
    case 'sexual_wellness':
      return {
        seoMetaTitle: 'Buy Sexual Wellness Online | Get Sexual Wellness Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Sexual Wellness online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Sexual Wellness Online',
      };
    case 'fragrances_deos':
      return {
        seoMetaTitle: 'Buy Fragrances and Deos Online | Get Fragrances and Deos Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Fragrances and Deos online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Fragrances and Deos Online',
      };
    case 'bath_body_handwash':
      return {
        seoMetaTitle: 'Buy Bath, Body & Hand Wash Online | Get Bath, Body & Hand Wash Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Bath, Body & Hand Wash online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Bath, Body & Hand Wash Online',
      };
    case 'makeup':
      return {
        seoMetaTitle: 'Buy Makeup Products Online | Get Makeup Products Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Makeup Products online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Makeup Products Online',
      };
    case 'oral_care':
      return {
        seoMetaTitle: 'Buy Oral Care Online | Get Oral Care Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Oral Care online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Oral Care Online',
      };
    case 'feminine_hygiene':
      return {
        seoMetaTitle: 'Buy Feminime Hygiene Online | Get Feminime Hygiene Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Feminime Hygiene online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Feminime Hygiene Online',
      };
    case 'health_wellness':
      return {
        seoMetaTitle: 'Buy Health and Wellness Online | Get Health and Wellness Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Health and Wellness online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Health and Wellness Online',
      };
    case 'mens_grooming':
      return {
        seoMetaTitle: 'Buy Mens Grooming Online | Get Mens Grooming Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Mens Grooming online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Mens Grooming Online',
      };
    case 'accessories_tools':
      return {
        seoMetaTitle: 'Buy Accessories and Tools Online | Get Accessories and Tools Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Accessories and Tools online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Accessories and Tools Online',
      };

    case 'fruits_and_vegetables':
      return {
        seoMetaTitle: 'Buy Fresh Vegetables & Fruits Online | Fruits & Vegetables Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy fresh fruits and vegetables online at LoveLocal. Avail great offers and discounts while you get your groceries delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Fresh Fruits, Vegetables Online',
      };
    // Sub Categories of Fruits & Vegetables
    case 'exotic':
      return {
        seoMetaTitle: 'Buy Exotic Vegetables Online | Get Exotic Vegetables Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Mens Grooming online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Exotic Vegetables Online',
      };
    case 'seasonal':
      return {
        seoMetaTitle: 'Buy Seasonal Vegetables Online | Get Seasonal Vegetables Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Seasonal Vegetables online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Seasonal Vegetables Online',
      };
    case 'organic_vegetables':
      return {
        seoMetaTitle: 'Buy Organic Vegetables Online | Get Organic Vegetables Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Organic Vegetables online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Organic Vegetables Online',
      };
    case 'all_vegatables':
      return {
        seoMetaTitle: 'Buy All Vegetables Online | Get All Vegetables Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All Vegetables online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Vegetables Online',
      };
    case 'fresh_vegetables':
      return {
        seoMetaTitle: 'Buy Fresh Vegetables Online | Get Fresh Vegetables Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Fresh Vegetables online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Fresh Vegetables Online',
      };
    case 'all_fruits':
      return {
        seoMetaTitle: 'Buy All Fruits Online | Get All Fruits Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All Fruits online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Fruits Online',
      };
    case 'herbs':
      return {
        seoMetaTitle: 'Buy Herbs Online | Get Herbs Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Herbs online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Herbs Online',
      };
    case 'fresh_fruits':
      return {
        seoMetaTitle: 'Buy Fresh Fruits Online | Get Fresh Fruits Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Fresh Fruits online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Fresh Fruits Online',
      };
    case 'organic_fruits':
      return {
        seoMetaTitle: 'Buy Organic Fruits Online | Get Organic Fruits Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Organic Fruits online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Organic Fruits Online',
      };
    case 'cut_fresh_vegetables':
      return {
        seoMetaTitle: 'Buy Cut Fresh Vegetables Online | Get Cut Fresh Vegetables Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cut Fresh Vegetables online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cut Fresh Vegetables Online',
      };

    case 'dairy':
      return {
        seoMetaTitle: 'Buy Dairy Products Online | Online Milk Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy milk online at LoveLocal. Avail great offers and discounts while you get your milk and other dairy products delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Milk, Cheese, Curd, Panner Online',
      };
    // Sub Categories of Dairy
    case 'cheese':
      return {
        seoMetaTitle: 'Buy Cheese Online | Get Cheese Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cheese online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cheese Online',
      };
    case 'dairy_products':
      return {
        seoMetaTitle: 'Buy Dairy Products Online | Get Dairy Products Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Dairy Products online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Dairy Products Online',
      };
    case 'icecream':
      return {
        seoMetaTitle: 'Buy Ice cream Online | Get Ice creams Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Ice cream online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Ice cream Online',
      };
    case 'all_dairy_products':
      return {
        seoMetaTitle: 'Buy All Products Online | Get All Products Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All Products online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Products Online',
      };
    case 'paneer_curd':
      return {
        seoMetaTitle: 'Buy Paneer and Curd Online | Get Paneer and Curd Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Paneer and Curd online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Paneer and Curd Online',
      };
    case 'milk':
      return {
        seoMetaTitle: 'Buy Milk Online | Get Milk Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Milk online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Milk Online',
      };
    case 'buttermilk_lassi':
      return {
        seoMetaTitle: 'Buy Buttermilk and Lassi Online | Get Buttermilk and Lassi Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Buttermilk and Lassi online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Buttermilk and Lassi Online',
      };

    case 'meat_and_seafood':
      return {
        seoMetaTitle: 'Order Chicken, Meat & Seafood Online | Meat Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy chicken, meat & seafood online at LoveLocal. Avail great offers and discounts while you get your non-veg products delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Chicken, Meat, Seafood Online',
      };
    // Sub Categories of Meat & Seafood
    case 'mutton_lamb':
      return {
        seoMetaTitle: 'Buy Mutton and Lamb Online | Get Mutton and Lamb Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Mutton and Lamb online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Mutton and Lamb Online',
      };
    case 'chicken':
      return {
        seoMetaTitle: 'Buy Chicken Online | Get Chicken Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Chicken online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Chicken Online',
      };
    case 'sausages_bacon_salami':
      return {
        seoMetaTitle: 'Buy Sausages, Bacon & Salami Online | Get Sausages, Bacon & Salami Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Sausages, Bacon & Salami online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Sausages, Bacon & Salami Online',
      };
    case 'seafood':
      return {
        seoMetaTitle: 'Buy seafood Online | Get seafood Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy seafood online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy seafood Online',
      };

    case 'maternity_and_baby_care':
      return {
        seoMetaTitle: 'Buy Maternity & Baby Care Items Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Maternity & Baby Care Items online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Maternity & Baby Care Items Online',
      };
    // Sub Categories of Maternity & Baby Care
    case 'bath_hygiene':
      return {
        seoMetaTitle: 'Buy Bath & Hygiene Online | Get Bath & Hygiene Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Bath & Hygiene online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Bath & Hygiene Online',
      };
    case 'diapers_wipes':
      return {
        seoMetaTitle: 'Buy Diapers & Wipes Online | Get Diapers & Wipes Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Diapers & Wipes online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Diapers & Wipes Online',
      };
    case 'baby_food':
      return {
        seoMetaTitle: 'Buy Baby Food Online | Get Baby Food Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Baby Food online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Baby Food Online',
      };
    case 'baby_accessories':
      return {
        seoMetaTitle: 'Buy Baby Accessories Online | Get Baby Accessories Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Baby Accessories online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Baby Accessories Online',
      };
    case 'baby_skin_hair_care':
      return {
        seoMetaTitle: 'Buy Baby Skin & Hair Care Online | Get Baby Skin & Hair Care Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Baby Skin & Hair Care online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Baby Skin & Hair Care Online',
      };
    case 'food_formula':
      return {
        seoMetaTitle: 'Buy Food & Formula Online | Get Food & Formula Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Food & Formula online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Food & Formula Online',
      };

    case 'household_and_cleaning':
      return {
        seoMetaTitle: 'Buy Household & Home Cleaning Products Online from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy household & cleaning supplies online at LoveLocal. Avail great offers and discounts while you get all type of cleaning products delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cleaning Supplies, Other Household Items Online',
      };
    // Sub Categories of Household & Home Cleaning
    case 'electronic_appliances':
      return {
        seoMetaTitle: 'Buy Electronic Appliances Online | Get Electronic Appliances Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Electronic Appliances online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Electronic Appliances Online',
      };
    case 'shoe_care':
      return {
        seoMetaTitle: 'Buy Shoe Care Online | Get Shoe Care Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Shoe Care online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Shoe Care Online',
      };
    case 'all_household_cleaning':
      return {
        seoMetaTitle: 'Buy All Household & Cleaning Online | Get All Household & Cleaning Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All Household & Cleaning online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Household & Cleaning Online',
      };
    case 'disposable_garbage_bag':
      return {
        seoMetaTitle: 'Buy Disposables & Garbage Bag, Foils Online | Get Disposables & Garbage Bag, Foils Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Disposables & Garbage Bag, Foils online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Disposables & Garbage Bag, Foils Online',
      };
    case 'home_furnishing_decor':
      return {
        seoMetaTitle: 'Buy Home Furnishings & Decor Online | Get Home Furnishings & Decor Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Home Furnishings & Decor online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Home Furnishings & Decor Online',
      };
    case 'deteregents_dishwash':
      return {
        seoMetaTitle: 'Buy Detergents & Dishwash Online | Get Detergents & Dishwash Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Detergents & Dishwash online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Detergents & Dishwash Online',
      };
    case 'all_purpose_cleaners':
      return {
        seoMetaTitle: 'Buy All Purpose Cleaners Online | Get All Purpose Cleaners Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All Purpose Cleaners online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Purpose Cleaners Online',
      };
    case 'freshners_repellants':
      return {
        seoMetaTitle: 'Buy Fresheners & Repellents Online | Get Fresheners & Repellents Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Fresheners & Repellents online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Fresheners & Repellents Online',
      };
    case 'mops_brushes_scrubs':
      return {
        seoMetaTitle: 'Buy Mops, Brushes & Scrubs Online | Get Mops, Brushes & Scrubs Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Mops, Brushes & Scrubs online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Mops, Brushes & Scrubs Online',
      };
    case 'kitchen_tools_accessories':
      return {
        seoMetaTitle: 'Buy Kitchen Tools & Accessories Online | Get Kitchen Tools & Accessories Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Kitchen Tools & Accessories online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Kitchen Tools & Accessories Online',
      };
    case 'bins_bathroomware':
      return {
        seoMetaTitle: 'Buy Bins & Bathroomware Online | Get Bins & Bathroomware Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Bins & Bathroomware online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Bins & Bathroomware Online',
      };

    case 'otc':
      return {
        seoMetaTitle: 'Buy OTC Products Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy OTC Products online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy OTC Products Online',
      };
    // Sub Categories of Chocolates, Snacks & Namkeen
    case 'otc_baby_infant':
      return {
        seoMetaTitle: 'Buy Baby & Infant Care Online | Get Baby & Infant Care Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Baby & Infant Care online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Baby & Infant Care Online',
      };
    case 'otc_all_products':
      return {
        seoMetaTitle: 'Buy All OTC Products Online | Get All OTC Products Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All OTC Products online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All OTC Products Online',
      };
    case 'otc_pain-relief':
      return {
        seoMetaTitle: 'Buy Pain Relief Online | Get Pain Relief Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Pain Relief online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Pain Relief Online',
      };
    case 'otc_digestive_pills':
      return {
        seoMetaTitle: 'Buy Digestive Pills Online | Get Digestive Pills Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Digestive Pills online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Digestive Pills Online',
      };
    case 'otc_diabetic_care':
      return {
        seoMetaTitle: 'Buy Diabetic Care Online | Get Diabetic Care Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Diabetic Care online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Diabetic Care Online',
      };
    case 'ots_womens_care':
      return {
        seoMetaTitle: 'Buy Womens Care Online | Get Womens Care Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Womens Care online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Womens Care Online',
      };
    case 'otc_personal_care':
      return {
        seoMetaTitle: 'Buy Personal Care Online | Get Personal Care Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Personal Care online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Personal Care Online',
      };
    case 'otc_chyawanprash':
      return {
        seoMetaTitle: 'Buy Chyawanprash & Health Foods Online | Get Chyawanprash & Health Foods Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Chyawanprash & Health Foods online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Chyawanprash & Health Foods Online',
      };
    case 'otc_syrups':
      return {
        seoMetaTitle: 'Buy Syrups Online | Get Syrups Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Syrups online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Syrups Online',
      };

    case 'chocolates_snacks_and_namkeen':
      return {
        seoMetaTitle: 'Buy Chocolates, Snacks & Namkeen Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Chocolates, Snacks & Namkeen online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Chocolates, Snacks & Namkeen Online',
      };
    // Sub Categories of Chocolates, Snacks & Namkeen
    case 'mukhwas':
      return {
        seoMetaTitle: 'Buy Mukhwas Online | Get Mukhwas Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Mukhwas online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Mukhwas Online',
      };
    case 'chocolates':
      return {
        seoMetaTitle: 'Buy Chocolates Online | Get Chocolates Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Chocolates online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Chocolates Online',
      };
    case 'candy':
      return {
        seoMetaTitle: 'Buy Candy Online | Get Candy Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Candy online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Candy Online',
      };
    case 'farsan_namkeen':
      return {
        seoMetaTitle: 'Buy Farsan & Namkeen Online | Get Farsan & Namkeen Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Farsan & Namkeen online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Farsan & Namkeen Online',
      };
    case 'cookies_biscuits':
      return {
        seoMetaTitle: 'Buy Cookies & Biscuits Online | Get Cookies & Biscuits Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cookies & Biscuits online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cookies & Biscuits Online',
      };
    case 'chips_wafers':
      return {
        seoMetaTitle: 'Buy Chips & Wafers Online | Get Chips & Wafers Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Chips & Wafers online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Chips & Wafers Online',
      };

    case 'food_and_beverages':
      return {
        seoMetaTitle: 'Buy Food & Beverages Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Food & Beverages online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Food & Beverages Online',
      };
    // Sub Categories of Food & Beverages
    case 'tea':
      return {
        seoMetaTitle: 'Buy Tea Online | Get Tea Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Tea online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Tea Online',
      };
    case 'instant_drinks_squash_syrups':
      return {
        seoMetaTitle: 'Buy Instant Drink Mixes, Squash & Syrups Online | Get Instant Drink Mixes, Squash & Syrups Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Instant Drink Mixes, Squash & Syrups online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Instant Drink Mixes, Squash & Syrups Online',
      };
    case 'cooked_snacks':
      return {
        seoMetaTitle: 'Buy Cooked Snacks Online | Get Cooked Snacks Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cooked Snacks online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cooked Snacks Online',
      };
    case 'drinks_beverages':
      return {
        seoMetaTitle: 'Buy Drinks Beverages Online | Get Drinks Beverages Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Drinks Beverages online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Drinks Beverages Online',
      };
    case 'drinks_beverages_shakes':
      return {
        seoMetaTitle: 'Buy Drinks Beverages Shakes Online | Get Drinks Beverages Shakes Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Drinks Beverages Shakes online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Drinks Beverages Shakes Online',
      };
    case 'cooked_burger_pizza_and_pasta':
      return {
        seoMetaTitle: 'Buy Cooked Burger Pizza And Pasta Online | Get Cooked Burger Pizza And Pasta Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cooked Burger Pizza And Pasta online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cooked Burger Pizza And Pasta Online',
      };
    case 'energy_soft_drinks':
      return {
        seoMetaTitle: 'Buy Energy & Soft Drinks Online | Get Energy & Soft Drinks Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Energy & Soft Drinks online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Energy & Soft Drinks Online',
      };
    case 'coffee':
      return {
        seoMetaTitle: 'Buy Coffee Online | Get Coffee Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Coffee online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Coffee Online',
      };
    case 'juices':
      return {
        seoMetaTitle: 'Buy Juices Online | Get Juices Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Juices online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Juices Online',
      };
    case 'water_soda':
      return {
        seoMetaTitle: 'Buy Water & Soda Online | Get Water & Soda Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Water & Soda online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Water & Soda Online',
      };
    case 'all_drinks':
      return {
        seoMetaTitle: 'Buy All Drinks Online | Get All Drinks Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All Drinks online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Drinks Online',
      };
    case 'indian_breads':
      return {
        seoMetaTitle: 'Buy Indian Breads Online | Get Indian Breads Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Indian Breads online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Indian Breads Online',
      };
    case 'health_drinks_supplements':
      return {
        seoMetaTitle: 'Buy Health Drinks & Supplements Online | Get Health Drinks & Supplements Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Health Drinks & Supplements online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Health Drinks & Supplements Online',
      };

    case 'ready_to_eat_instant_and_frozen_food':
      return {
        seoMetaTitle: 'Buy Ready to Eat Instant & Frozen Food Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Ready to Eat Instant & Frozen Food online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Ready to Eat Instant & Frozen Food Online',
      };
    // Sub Categories of Ready to Eat Instant & Frozen Food
    case 'sauces_ketchups':
      return {
        seoMetaTitle: 'Buy Sauces & Ketchups Online | Get Sauces & Ketchups Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Sauces & Ketchups online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Sauces & Ketchups Online',
      };
    case 'papad_fryums':
      return {
        seoMetaTitle: 'Buy Papads & Fryums Online | Get Papads & Fryums Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Papads & Fryums online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Papads & Fryums Online',
      };
    case 'ready_meals_mixes':
      return {
        seoMetaTitle: 'Buy Ready made Meals & Mixes Online | Get Ready made Meals & Mixes Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Ready made Meals & Mixes online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Ready made Meals & Mixes Online',
      };
    case 'granola_protein_bars':
      return {
        seoMetaTitle: 'Buy Granola & Protein Bars Online | Get Granola & Protein Bars Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Granola & Protein Bars online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Granola & Protein Bars Online',
      };
    case 'noodles_pasta_vermicelli':
      return {
        seoMetaTitle: 'Buy Noodles, Pasta & Vermicelli Online | Get Noodles, Pasta & Vermicelli Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Noodles, Pasta & Vermicelli online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Noodles, Pasta & Vermicelli Online',
      };
    case 'home_dessert_mixes':
      return {
        seoMetaTitle: 'Buy Home Dessert Mixes Online | Get Home Dessert Mixes Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Home Dessert Mixes online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Home Dessert Mixes Online',
      };
    case 'jams_honey_spreads':
      return {
        seoMetaTitle: 'Buy Jams, Honey & Spreads Online | Get Jams, Honey & Spreads Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Jams, Honey & Spreads online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Jams, Honey & Spreads Online',
      };
    case 'pickles_chutneys':
      return {
        seoMetaTitle: 'Buy Pickles & Chutneys Online | Get Pickles & Chutneys Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Pickles & Chutneys online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Pickles & Chutneys Online',
      };
    case 'frozen_food':
      return {
        seoMetaTitle: 'Buy Frozen Food Online | Get Frozen Food Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Frozen Food online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Frozen Food Online',
      };
    case 'cereals_muesli_oats':
      return {
        seoMetaTitle: 'Buy Cereals, Muesli & Oats Online | Get Cereals, Muesli & Oats Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cereals, Muesli & Oats online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cereals, Muesli & Oats Online',
      };
    case 'soups':
      return {
        seoMetaTitle: 'Buy Soups Online | Get Soups Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Soups online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Soups Online',
      };
    case 'chyawanprash_health_foods':
      return {
        seoMetaTitle: 'Buy Chyawanprash & Health Foods Online | Get Chyawanprash & Health Foods Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Chyawanprash & Health Foods online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Chyawanprash & Health Foods Online',
      };

    case 'dry_fruits_mithai_and_sweets':
      return {
        seoMetaTitle: 'Buy Dry Fruits, Mithai & Sweets Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Dry Fruits, Mithai & Sweets online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Dry Fruits, Mithai & Sweets Online',
      };
    // Sub Categories of Dry Fruits, Mithai & Sweets
    case 'dry_fruits_nuts':
      return {
        seoMetaTitle: 'Buy Dry Fruits & Nuts Online | Get Dry Fruits & Nuts Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Dry Fruits & Nuts online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Dry Fruits & Nuts Online',
      };
    case 'mithai':
      return {
        seoMetaTitle: 'Buy Mithai Online | Get Mithai Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Mithai online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Mithai Online',
      };
    case 'seeds':
      return {
        seoMetaTitle: 'Buy Seeds Online | Get Seeds Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Seeds online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Seeds Online',
      };

    case 'bakery_baking_and_eggs':
      return {
        seoMetaTitle: 'Buy Bakery, Baking & Eggs Supplies Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Bakery, Baking & Eggs Supplies online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Bakery, Baking & Eggs Supplies Online',
      };
    // Sub Categories of Bakery, Baking & Eggs Supplies
    case 'cookies':
      return {
        seoMetaTitle: 'Buy Cookies Online | Get Cookies Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cookies online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cookies Online',
      };
    case 'breads_buns':
      return {
        seoMetaTitle: 'Buy Breads & Buns Online | Get Breads & Buns Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Breads & Buns online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Breads & Buns Online',
      };
    case 'toast_khari':
      return {
        seoMetaTitle: 'Buy Toast & Khari Online | Get Toast & Khari Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Toast & Khari online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Toast & Khari Online',
      };
    case 'muffins_pies_tarts':
      return {
        seoMetaTitle: 'Buy Muffins, Pies, Tarts Online | Get Muffins, Pies, Tarts Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Muffins, Pies, Tarts online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Muffins, Pies, Tarts Online',
      };
    case 'eggs':
      return {
        seoMetaTitle: 'Buy Eggs Online | Get Eggs Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Eggs online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Eggs Online',
      };
    case 'cakes_pastries':
      return {
        seoMetaTitle: 'Buy Cakes Online | Get Cakes Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cakes online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cakes Online',
      };
    case 'baking_supplies':
      return {
        seoMetaTitle: 'Buy Baking Supplies Online | Get Baking Supplies Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Baking Supplies online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Baking Supplies Online',
      };
    case 'cakes_pastery_primary':
      return {
        seoMetaTitle: 'Buy Cakes Online | Get Cakes Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cakes online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cakes Online',
      };
    case 'cooking_needs':
      return {
        seoMetaTitle: 'Buy Cooking Needs Online | Get Cooking Needs Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cooking Needs online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cooking Needs Online',
      };

    case 'cake_ingredients':
      return {
        seoMetaTitle: 'Buy Cake Ingredients Online | Get Cake Ingredients Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cake Ingredients online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cake Ingredients Online',
      };

    case 'covid_essentials':
      return {
        seoMetaTitle: 'Buy Covid Essentials Online | Get Covid Essentials Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Covid Essentials online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Covid Essentials Online',
      };
    // Sub Categories of Covid Essentials
    case 'hand_wash':
      return {
        seoMetaTitle: 'Buy Hand Wash Online | Get Hand Wash Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Hand Wash online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Hand Wash Online',
      };
    case 'hand_sanitizers':
      return {
        seoMetaTitle: 'Buy Hand Sanitizers Online | Get Hand Sanitizers Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Hand Sanitizers online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Hand Sanitizers Online',
      };
    case 'devices':
      return {
        seoMetaTitle: 'Buy Devices Online | Get Devices Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Devices online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Devices Online',
      };
    case 'immunity_boosters':
      return {
        seoMetaTitle: 'Buy Immunity Boosters Online | Get Immunity Boosters Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Immunity Boosters online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Immunity Boosters Online',
      };
    case 'disinfectants':
      return {
        seoMetaTitle: 'Buy Disinfectants Online | Get Disinfectants Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Disinfectants online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Disinfectants Online',
      };
    case 'gloves':
      return {
        seoMetaTitle: 'Buy Gloves Online | Get Gloves Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Gloves online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Gloves Online',
      };
    case 'respiratory_masks':
      return {
        seoMetaTitle: 'Buy Respiratory Masks Online | Get Respiratory Masks Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Respiratory Masks online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Respiratory Masks Online',
      };

    case 'pet_supplies':
      return {
        seoMetaTitle: 'Buy Pet Care Supplies Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Pet Care Supplies online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Pet Care Supplies Online',
      };
    // Sub Categories of Pet Care Supplies
    case 'pet_toys':
      return {
        seoMetaTitle: 'Buy Toys Online | Get Toys Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Toys online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Toys Online',
      };
    case 'pet_accessories':
      return {
        seoMetaTitle: 'Buy Accessories Online | Get Accessories Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Accessories online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Accessories Online',
      };
    case 'dog_food':
      return {
        seoMetaTitle: 'Buy Dog Food Online | Get Dog Food Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Dog Food online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Dog Food Online',
      };
    case 'all_pet_supplies':
      return {
        seoMetaTitle: 'Buy All Pet Supplies Online | Get All Pet Supplies Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All Pet Supplies online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Pet Supplies Online',
      };
    case 'cat_food':
      return {
        seoMetaTitle: 'Buy Cat Food Online | Get Cat Food Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Cat Food online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Cat Food Online',
      };

    case 'others':
      return {
        seoMetaTitle: 'Buy Other Products Online | Get Other Products Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Other Products online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy Other Products Online',
      };

    case 'stationary':
      return {
        seoMetaTitle: 'Buy Stationary Supplies Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Stationary Supplies online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Stationary Supplies Online',
      };

    case 'pooja':
      return {
        seoMetaTitle: 'Buy Pooja Supplies Online | Online Grocery Delivery from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy Pooja Supplies online at LoveLocal. Avail great offers and discounts while you get your staple products delivered from stores near your location. Try LoveLocal today.',
        h1: 'Buy Pooja Supplies Online',
      };

    case 'uncategorized':
      return {
        seoMetaTitle: 'Buy All Products Online | Get All Products Delivered from Local Shops | LoveLocal',
        seoMetaDescription: 'Buy All Products online at LoveLocal. Avail great offers and discounts while you get all type of flours delivered from local stores near your location. Try LoveLocal today.',
        h1: 'Buy All Products Online',
      };

    default:
      return {
        seoMetaTitle: `Buy ${displayName} Online | ${displayName} Delivery from Local Shops | LoveLocal`,
        seoMetaDescription: `Buy ${displayName} online at LoveLocal. Avail great offers and discounts while you get your ${displayName} products delivered from stores near your location. Try LoveLocal today.`,
        h1: '',
      };
  }
};

export const storeCategoriesMappingInfo = (
  category = '', locality = '', city = '',
) => {
  switch (category) {
    case 'groceries':
      return {
        title: 'Grocery',
        description: 'Grocery',
        seoMetaTitle: `Order Grocery Online in ${locality ? `${locality}, ` : ''} ${city} | Grocery Shopping Stores in ${locality ? `${locality}, ` : ''} ${city} | LoveLocal`,
        seoMetaDescription: `Online Grocery Shopping in ${locality ? `${locality}, ` : ''} ${city}. Browse through grocery shops located near you. With various payment modes, exicting offers and home delivery option available, get your grocery delivered in ${locality ? `${locality}, ` : ''} ${city} with ease. Try LoveLocal today.`,
        categoryTitleh1: `Grocery Stores in ${locality ? `${locality}, ` : ''} ${city}`,
      };

    case 'dairies':
      return {
        title: 'Dairy',
        description: 'Dairy',
        seoMetaTitle: `Dairy Farms & Shops in ${locality ? `${locality}, ` : ''} ${city} | Online Milk Delivery in ${locality ? `${locality}, ` : ''} ${city} | LoveLocal`,
        seoMetaDescription: `Browse through dairy shops & farms in ${locality ? `${locality}, ` : ''} ${city} & order all types of milk & milk-based products online. With various payment modes & delivery options, get your milk delivered in ${locality ? `${locality}, ` : ''} ${city} with ease. Try LoveLocal today.`,
        categoryTitleh1: `Online Dairy Farms & Shops in ${locality ? `${locality}, ` : ''} ${city}`,
      };

    case 'meat-fish-shops':
      return {
        title: 'Meat & Fish',
        description: 'Meat & Fish',
        seoMetaTitle: `Fresh Fish, Chicken, Muttton & Meat Delivery Online in ${locality ? `${locality}, ` : ''} ${city} from Local Shops | LoveLocal `,
        seoMetaDescription: `Buy fresh fish, chicken & meat online in ${locality ? `${locality}, ` : ''} ${city}, directly from stores located near you. Avail various payment modes & have your fish, chicken, meat delivered in ${locality ? `${locality}, ` : ''} ${city} with ease. Try LoveLocal today.`,
        categoryTitleh1: `Fish, Chicken & Meat Shops in ${locality ? `${locality}, ` : ''} ${city}`,
      };
    case 'bakeries':
      return {
        title: 'Bakery',
        description: 'Bakery Products',
        seoMetaTitle: `Bakery Shops in ${locality ? `${locality}, ` : ''} ${city} | Order Cake Online in ${locality ? `${locality}, ` : ''} ${city} | LoveLocal`,
        seoMetaDescription: `Order cake & other bakery items online from bakery shops in ${locality ? `${locality}, ` : ''} ${city}. With various payment modes & delivery options get birthday cakes delivered in ${locality ? `${locality}, ` : ''} ${city} with ease. Browse through bakery shops near you on LoveLocal.`,
        categoryTitleh1: `Online Bakery Shops in ${locality ? `${locality}, ` : ''} ${city}`,
      };
    case 'fruits-vegetables-shops':
      return {
        title: 'Fruits & Vegetables',
        description: 'Fruits & Vegetables',
        seoMetaTitle: `Order Vegetable Online in ${locality ? `${locality}, ` : ''} ${city} | Buy Vegetables & Fruits online from Local Shops | LoveLocal`,
        seoMetaDescription: `With LoveLocal you can buy fruits and vegetables online in ${locality ? `${locality}, ` : ''} ${city} from local shops near you. With various payment modes & home delivery options, order fruits & vegetables online in ${locality ? `${locality}, ` : ''} ${city} & have them delivered with ease.`,
        categoryTitleh1: `Buy Fruits & Vegetables Online in ${locality ? `${locality}, ` : ''} ${city}`,
      };
    case 'cosmetics-shops':
      return {
        title: 'Cosmetics',
        description: 'Cosmetics',
        seoMetaTitle: `Online Cosmetic Shops in ${locality ? `${locality}, ` : ''} ${city} | Buy Makeup & Cosmetics Online | LoveLocal`,
        seoMetaDescription: `Buy makeup & cosmetic products online in ${locality ? `${locality}, ` : ''} ${city}. Browse these cosmetic shops, avail exciting offers, various payment modes and get your makeup & cosmetic products delivered with easy. Try LoveLocal today.`,
        categoryTitleh1: `Online Cosmetic Shops in ${locality ? `${locality}, ` : ''} ${city}`,
      };
    case 'launderers':
      return {
        title: 'Laundry',
        description: 'Laundry',
        seoMetaTitle: `Laundry Shops in ${locality ? `${locality}, ` : ''} ${city} | Best Laundry Services in ${locality ? `${locality}, ` : ''} ${city} | LoveLocal`,
        seoMetaDescription: `Check all the laundry shops & Dry cleaners in ${locality ? `${locality}, ` : ''} ${city}. Find the best laundry services on the LoveLocal website. With various payment modes and home delivery option available, selecting a laundry service has never been this easy. Try it today.`,
        categoryTitleh1: `Laundry Shops in ${locality ? `${locality}, ` : ''} ${city}`,
      };

    case 'pharmacies':
      return {
        title: 'Chemist',
        description: 'Medicines',
        seoMetaTitle: `Pharmacy & Medical Stores in ${locality ? `${locality}, ` : ''} ${city} | Online Medicine Delivery in ${locality ? `${locality}, ` : ''} ${city} | LoveLocal`,
        seoMetaDescription: `Order medicines online in ${locality ? `${locality}, ` : ''} ${city} from pharmacies & medical stores near you. Buy medicines online from chemist shops using various payment options. Online medicine delivery made easy with LoveLocal.`,
        categoryTitleh1: `Online Medical Stores in ${locality ? `${locality}, ` : ''} ${city}`,
      };

    case 'restaurants':
      return {
        title: 'Restaurant',
        description: 'Food',
        seoMetaTitle: `Restaurants in ${locality ? `${locality}, ` : ''} ${city} | Order Food from Best Local Restaurants | LoveLocal`,
        seoMetaDescription: `Browse through all Restaurants in ${locality ? `${locality}, ` : ''} ${city} & order food directly from eateries located near you. Pay online & get your food delivered from best local restaurants in ${locality ? `${locality}, ` : ''} ${city}. Try LoveLocal today.`,
        categoryTitleh1: `Restaurants in ${locality ? `${locality}, ` : ''} ${city}`,
      };

    case 'stationers':
      return {
        title: 'Stationary Shop',
        description: 'Stationary',
        seoMetaTitle: `Online Stationery Shops in ${locality ? `${locality}, ` : ''} ${city} | Stationery Items Delivery from Local Shop Near You | LoveLocal`,
        seoMetaDescription: `Browse through all stationary shops online in ${locality ? `${locality}, ` : ''} ${city} & shop for stationery items directly from these general stores located near you. With various payment modes & delivery options, LoveLocal makes stationery delivery hassle-free.`,
        categoryTitleh1: `Online Stationery Stores in ${locality ? `${locality}, ` : ''} ${city}`,
      };

    case 'pet-supplies':
      return {
        title: 'Pet Shops',
        description: 'Pet Supplies',
        seoMetaTitle: `Pet Supplies Stores in ${locality ? `${locality}, ` : ''} ${city} |  Buy Pet Food Online from Local Shops | LoveLocal`,
        seoMetaDescription: `Browse through all pet supplies stores in ${locality ? `${locality}, ` : ''} ${city}. Get your pet food & supplies from stores located near you. With various payment modes and home delivery option available, order your pet supplies with ease. Try LoveLocal today.`,
        categoryTitleh1: `Pet Food & Pet Supplies Stores in ${locality ? `${locality}, ` : ''} ${city}`,
      };

    case 'dry-fruits-nuts':
    case 'dry-fruits-and-nuts':
      return {
        title: 'Dry fruits and Nuts Shops',
        description: 'Dry Fruits & Nuts',
        seoMetaTitle: `Online Dry Fruits & Nuts Shopping in ${locality ? `${locality}, ` : ''} ${city} | Dry Fruits & Nuts Stores in ${locality ? `${locality}, ` : ''} ${city} | LoveLocal`,
        seoMetaDescription: `Order dry fruits & nuts online from dry fruits stores in ${locality ? `${locality}, ` : ''} ${city}. With various payment modes & delivery options get dry fruits & nuts delivered in ${locality ? `${locality}, ` : ''} ${city} with ease. Browse through dry fruits & nuts stores near you on LoveLocal.`,
        categoryTitleh1: `Online Dry Fruits & Nuts Stores in ${locality ? `${locality}, ` : ''} ${city}`,
      };

    case 'sweets-farsan-shops':
      return {
        title: 'Sweets & Farsan Shops',
        description: 'Sweets & Farsan',
        seoMetaTitle: `Sweets & Farsan Shops in ${locality ? `${locality}, ` : ''} ${city} | Order Sweets Online in ${locality ? `${locality}, ` : ''} ${city} | LoveLocal`,
        seoMetaDescription: `Order the best sweets & farsan online in ${locality ? `${locality}, ` : ''} ${city}. Browse through sweets & farsan shops near you. Use various payment modes & delivery options to get your sweets delivered in ${locality ? `${locality}, ` : ''} ${city} with ease. Try LoveLocal today.`,
        categoryTitleh1: `Online Sweets & Farsan Shops in ${locality ? `${locality}, ` : ''} ${city}`,
      };

    default:
      return {
        title: 'Grocery',
        description: 'Grocery',
        seoMetaTitle: `Order Grocery Online in ${locality ? `${locality}, ` : ''} ${city} | Grocery Stores in ${locality ? `${locality}, ` : ''} ${city} | LoveLocal`,
        seoMetaDescription: `Order groceries online in ${locality ? `${locality}, ` : ''} ${city}. Browse through grocery stores located near you. With various payment modes and home delivery option available, carry out your grocery shopping online with ease. Try LoveLocal today.`,
        categoryTitleh1: `Order Grocery Online in ${locality ? `${locality}, ` : ''} ${city}`,
      };
  }
};

export const popularCategories = [
  {
    title: 'Groceries',
    urlPath: '',
  },
  {
    title: 'Fruits & Vegetables Shops',
    urlPath: '',
  },
  {
    title: 'Pharmacies',
    urlPath: '',
  },
  {
    title: 'Meat & Fish Shops',
    urlPath: '',
  },
  {
    title: 'Dairies',
    urlPath: '',
  },
  {
    title: 'Bakeries',
    urlPath: '',
  },
];

export const getSEOFriendlyProductName = (text = '') => {
  const parts = getSEOFriendlyURL(text).replace(/\d/g, '').split('-');
  parts.pop();
  return parts.filter((item) => !!item).join('-');
};

export const getSeoShopUrl = (
  country = '',
  city = '',
  locality = '',
  storeType = '',
  storeName = '',
  code,
  query = '',
) => ({
  hasSeoData: !!(city && locality && storeType),
  shopUrl: `/${country || 'india'}/${city || 'othercities'}/${
    getSEOFriendlyURL(locality) || 'otherlocalities'}/${
    getSEOFriendlyURL(storeType) || 'othercategories'}/${
    getSEOFriendlyURL(storeName)}-${code}${query}`.toLowerCase(),
});

export const getSeoTitle = (title = '') => (
  title.trim()
    .replace(/(-)/g, ' ')
    .toLowerCase().split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
    // .replace(/(^\w{1})|(\s+\w{1})/g, (text) => text.toUpperCase())
);

export const getSeoProductUrl = (productId, productName, storeId) => ({
  pathName: `/products/${getSEOFriendlyProductName(productName)}-${productId}`,
  searchParams: `${storeId ? `?sid=${storeId}` : ''}`,
  state: {
    scrollToTop: true,
  },
});

export const uom = [
  {
    displayName: 'Kilogram (Kg)',
    shortName: 'Kg',
    id: 'KGs',
  },
  {
    displayName: 'Gram (g)',
    shortName: 'g',
    id: 'GMs',
  },
  {
    displayName: 'Meter (m)',
    shortName: 'm',
    id: 'Mtrs',
  },
  {
    displayName: 'Centimeter (cm)',
    shortName: 'cm',
    id: 'CMS',
  },
  {
    displayName: 'Foot (foot)',
    shortName: 'Foot',
    id: 'Foot',
  },
  {
    displayName: 'Inch (inch)',
    shortName: 'Inch',
    id: 'Inch',
  },
  {
    displayName: 'Litre (l)',
    shortName: 'L',
    id: 'L',
  },
  {
    displayName: 'Millilitre (ml)',
    shortName: 'ml',
    id: 'ML',
  },
  {
    displayName: 'Item (itm)',
    shortName: 'itm',
    id: 'Units',
  },
  {
    displayName: 'Packet (pkt)',
    shortName: 'pkt',
    id: 'packet',
  },
  {
    displayName: 'Bottle (btl)',
    shortName: 'btl',
    id: 'bottle',
  },
  {
    displayName: 'Carton (crtn)',
    shortName: 'crtn',
    id: 'carton',
  },
  {
    displayName: 'Canister (cnstr)',
    shortName: 'cnstr',
    id: 'canister',
  },
];

export const getFreeBackImages = [
  {
    image: '/images/sfo_01.png',
  },
  {
    image: '/images/sfo_02.png',
  },
  {
    image: '/images/sfo_03.png',
  },
];

export const seoTitleAndMetaDescForStores = {
  38: {
    title: 'GROCERIES',
    seoTitle: 'Online grocery shopping | Grocery from Local Shop on LoveLocal',
    seoMetaDescription: 'Online grocery shopping from local shops nearby. With various payment modes, exicting offers and home delivery option available. Get all from your favorite grocery shop. Try LoveLocal today.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/groceries-38',
    h1Tag: 'Online Grocery Shopping from trsuted local shops',
  },
  50: {
    title: 'PHARMACIES',
    seoTitle: 'Find Nearby Medical Stores | Shop Medicines Locally on LoveLocal',
    seoMetaDescription: 'Discover Medical Stores Near Me for Easy Online Medicine Orders. Shop at Local Pharmacies with Multiple Payment Options. Fast Medicine Delivery via LoveLocal.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/pharmacies-50',
    h1Tag: 'Shop from medical stores near you',
  },
  39: {
    title: 'DAIRIES',
    seoTitle: 'Dairy Shops Near You | Buy Milk from Local Stores on LoveLocal',
    seoMetaDescription: 'Browse through dairy shop near me & order all types of milk & milk-based products online. With various payment modes & delivery options, get your milk delivered with ease. Try LoveLocal today.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/dairies-39',
    h1Tag: 'Browse Dairy Shop Near You',
  },
  46: {
    title: 'Cosmetics Shops',
    seoTitle: 'Cosmetic Shop Near Me | Buy Makeup Items from Local Stores on LoveLocal',
    seoMetaDescription: 'Buy makeup from cosmetic shop near me online. Browse products from cosmetic store near you, avail exciting offers, various payment modes and get your makeup & cosmetic products delivered with easy. Try LoveLocal today.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/cosmetics-shops-46',
    h1Tag: 'Browse Cosmetic Stores Near You',
  },
  40: {
    title: 'MEAT & FISH SHOPS',
    seoTitle: 'Meat & Fish Shops Near Me | Buy Chicken & Fish from Local Stores on LoveLocal',
    seoMetaDescription: 'Buy from meat and fish shop near me online from stores located near you. Avail various payment modes & have your meat and fish, chcken delivered with ease. Try LoveLocal today.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/meat-fish-shops-40',
    h1Tag: 'Browse Meat & Fish Stores Near You',
  },
  45: {
    title: 'FRUITS & VEGETABLES SHOPS',
    seoTitle: 'Fresh Fruits Online|  Buy from Local Stores on LoveLocal',
    seoMetaDescription: 'With LoveLocal you can buy from fruits and vegetables shop near you online. With various payment modes & home delivery options, order fresh fruits online & have them delivered with ease.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/fruits-vegetables-shops-45',
    h1Tag: 'Browse Fruits & Vegetable Stores Near You',
  },
  54: {
    title: 'Sweets Farsan Shops',
    seoTitle: 'Sweet Shops Near Me | Buy Mithai from Local Stores on LoveLocal',
    seoMetaDescription: 'Order from best sweets shop near me online. Browse through different sweet shops. Use various payment modes & delivery options to get your sweets delivered with ease. Try LoveLocal today.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/sweets-farsan-shops-54',
    h1Tag: 'Browse Sweets & Farsan Stores Near You',
  },
  62: {
    title: 'DRY FRUITS AND NUTS',
    seoTitle: 'Dry Fruits Shops Online | Buy Dry Fruits & Nuts on LoveLocal',
    seoMetaDescription: 'Order dry fruits & nuts online from dry fruits shops near me. With various payment modes & delivery options get dry fruits & nuts delivered with ease. Try LoveLocal today.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/dry-fruits-and-nuts-62',
    h1Tag: 'Browse Dry Fruits & Nuts Stores Near You',
  },
  57: {
    title: 'PET SUPPLIES',
    seoTitle: 'Pet Shops Near Me | Buy from Pet Supplies stores on LoveLocal',
    seoMetaDescription: 'Browse through all pet supplies stores near you. With various payment modes and home delivery option available. Order from pet shops near me on LoveLocal today.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/pet-supplies-57',
    h1Tag: 'Browse Dry Fruits & Nuts Stores Near You',
  },
  41: {
    title: 'BAKERIES',
    seoTitle: 'Shop from nearby bakery shop| Cake Delivery on LoveLocal',
    seoMetaDescription: 'Order from near by bakery shop online. With various payment modes & delivery options get birthday cakes delivered with ease. Try LoveLocal today.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/bakeries-41',
    h1Tag: 'Browse Pet Supplies Stores Near You',
  },
  53: {
    title: 'STATIONARY',
    seoTitle: 'Stationary shop Near Me | Buy Stationary Items from Local Stores on LoveLocal',
    seoMetaDescription: 'Browse local stationary shop near you online & shop for stationery items directly from these general stores. With various payment modes & delivery options, LoveLocal makes stationery near me hassle-free.',
    canonicalUrl: 'https://www.lovelocal.in/shops/c/stationers-53',
    h1Tag: 'Browse Bakery Stores Near You',
  },
  ALL_SHOPS: {
    title: 'ALL_SHOPS',
    seoTitle: 'All Shops Near Me | Buy All Shops from Local Stores on LoveLocal',
    seoMetaDescription: 'Online grocery shopping through shops located near you. With various payment modes, exicting offers and home delivery option available, get your daily essentials delivered with ease. Try LoveLocal today.',
    canonicalUrl: 'https://www.lovelocal.in/shops',
    h1Tag: 'Browse Shops Near You',
  },
};
