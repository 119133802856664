const String = {
  // Home Page

  HOME_TITLE: {
    en: 'LoveLocal Partner | Aapki Apni Digital Dukaan',
    hi: 'लवलोकल पार्टनर | आपकी अपनी डिजिटल दूकान ',
  },

  TAKE_BUSINESS_ONLINE_1: {
    en: 'Take Your Retail',
    hi: 'अपना रिटेल व्यापार',
  },

  TAKE_BUSINESS_ONLINE_2: {
    en: 'Business Online',
    hi: 'ऑनलाइन करें',
  },

  PARTNER_WITH_US_1: {
    en: 'Partner with us! Create your own',
    hi: 'हमारे भागीदार बनें। लवलोकल पर अपनी',
  },

  PARTNER_WITH_US_2: {
    en: 'Digital Dukaan on LoveLocal',
    hi: 'खुद की डिजिटल दुकान बनाएँ',
  },

  AWARDS_AND_RECOGNITION: {
    en: 'Awards & Recognition',
    hi: 'पुरस्कार और मान्यता',
  },

  BENEFIT_OTHER_RETAILERS: {
    en: 'Benefit 800,000+ Other Retailers Like you',
    hi: 'Benefit 800,000+ Other Retailers Like you',
  },

  // USP-1
  SETUP_BUSINESS_ONLINE: {
    en: 'Setup Your Online Business',
    hi: 'अपनी ऑनलाइन दूकान- एप्प और वेब पर बनाएं',
  },

  SETUP_BUSINESS_ONLINE_DUKAAN: {
    en: 'Setup Your Online Dukaan',
    hi: 'अपनी ऑनलाइन दूकान- एप्प और वेब पर बनाएं',
  },

  BUILD_STRONG_DIGITAL_PRESENCE: {
    en: 'Build a strong digital presence',
    hi: 'बनाएँ अपनी ऑनलाइन शॉप (डिजिटल दुकान), लवलोकल एप्प और वेब पे',
  },

  GET_ECOMMERCE_WEB: {
    en: 'Get an eCommerce website and a customer facing in-app shop on the LoveLocal app',
    hi: 'किसी भी प्रकार का हार्ड-वेयर या सॉफ्टवेयर डालने की आवश्यकता नहीं है, बस एप्प डाउनलोड करें!',
  },

  START_ACCEPTING_DIGITAL_PAYMENT: {
    en: 'Start accepting digital payments from all popular clients and vendors',
    hi: 'कस्टमर्स से सीधा डिजिटल पेमेंट स्वीकार करें- डेबिट, क्रेडिट कार्ड, नेट बैंकिंग या सारे मुख्य इ-वॉलेट के माध्यम से',
  },

  EXITING_LOVELOCAL_SPONSORED: {
    en: 'Exciting LoveLocal-sponsored offers that will benefit your customers',
    hi: 'लवलोकल प्रायोजित डील्स एवं ऑफर्स का फायदा अपने कस्टमर्स को दें',
  },

  // USP-2
  ORDER_MANAGEMENT_SYSTEM: {
    en: 'Easy Catalogue & Order Management System',
    hi: 'कैटेलॉग एवं आर्डर मैनेजमेंट सिस्टम',
  },

  MANAGE_PRODUCTS: {
    en: 'Manage products and incoming orders',
    hi: 'एक संयुक्त डैशबोर्ड पे सारे प्रोडक्ट्स और नए ऑर्डर्स देखें और मैनेज करें',
  },

  EASILY_ADD_MANAGE_PRODUCTS: {
    en: 'Easily add and edit products, prices, offers with the fastest and most easy-to-use Catalogue Management System',
    hi: 'हमारी बेहद आसान कैटेलॉग मैनेजमेंट सिस्टम से अब बड़ी आसानी से प्रोडक्ट्स को जोड़ें या सम्पादित करें, उनकी प्राइस बदलें या ऑफर्स जोड़ें',
  },

  MANAGE_INCOMING_ORDERS: {
    en: 'Manage incoming orders easily. Give your customers both Home Delivery and Pickup From Shop options',
    hi: 'नए ऑर्डर्स को आसानी से मैनेज करें और कस्टमर्स को होम डिलीवरी एवं स्टोर पिक अप की भी सुविधा दें',
  },

  // USP-3
  POWERFUL_BUSINESS_ANALYTICS: {
    en: 'Powerful Business Analytics',
    hi: 'पावरफुल बिज़नेस एनालिटिक्स',
  },

  UNDERSTAND_HOW_GROW: {
    en: 'Understand how to grow your business',
    hi: 'समझें कि अपने व्यवसाय को कैसे बढ़ाया जाए',
  },

  UNDERSTAND_BUSINESS_PERFORMANCE: {
    en: 'Understand your daily, weekly, and monthly business performance',
    hi: 'अपने दैनिक, साप्ताहिक और मासिक व्यावसायिक परफॉर्मेंस को समझें',
  },

  GET_KNOWLEDGE: {
    en: 'Get detailed knowledge about new, repeat, inactive, and churned customers',
    hi: 'नए, दोहराने, निष्क्रिय और मंथन किए गए ग्राहकों के बारे में विस्तृत जानकारी प्राप्त करें',
  },

  UNDERSTAND_SHOPPING_PARTNERS: {
    en: 'Understand shopping patterns of your top customers to grow your revenue',
    hi: 'अपने आय बढ़ाने के लिए अपने शीर्ष ग्राहकों के खरीदारी पैटर्न को समझें',
  },

  // USP-4
  LOYALTY_CRM: {
    en: 'Loyalty & CRM',
    hi: 'लॉयल्टी & सीआरएम',
  },

  KEEP_CUSTOMERS_COMING: {
    en: 'Keep your customers coming back',
    hi: 'अपने ग्राहकों वापस को आते रहने दें',
  },

  RUN_YOUR_OWN_LOYALTY: {
    en: 'Run your very own loyalty program and take advantage of a customizable rewards program that fits your budget. Accept points for discounts at your shop',
    hi: 'अपने लॉयल्टी कार्यक्रम को चलाएं और एक अनुकूलन योग्य पुरस्कार कार्यक्रम का लाभ उठाएं जो आपके बजट के अनुकूल हो। अपनी दुकान पर छूट के लिए पॉइंट स्वीकार करें',
  },

  SHARE_DIGITAL_INVOICE: {
    en: 'Share digital invoice, reward points and offers with customers',
    hi: 'ग्राहकों के साथ डिजिटल चालान, इनाम पॉइंट और ऑफ़र साझा करें',
  },

  // And Much More
  AND_MUCH_MORE: {
    en: 'And Much More...',
    hi: 'और भी बहुत कुछ...',
  },

  // Our Promisees
  OUR_PROMISEES: {
    en: 'Our Promise to You',
    hi: 'आप को हमारा वादा',
  },

  FULL_SECURITY_STORES: {
    en: 'Full security of your store’s and customers’ data.',
    hi: 'आपके स्टोर और ग्राहकों के डेटा की पूर्ण सुरक्षा।',
  },

  WE_NEVER_SHARE_INFO: {
    en: 'We never share your customer information.',
    hi: 'हम कभी भी आपकी ग्राहक जानकारी साझा नहीं करते हैं।',
  },

  // Our Happy Retailers
  OUR_HAPPY_RETAILERS: {
    en: 'Our Happy Retailers',
    hi: 'हमारे हैप्पी रिटेलर्स',
  },

  // Thanks Page

  THANKS: {
    en: 'THANKS!',
    hi: 'धन्यवाद!',
  },

  THANKS_FOR_INTEREST: {
    en: 'Thanks for your interest. Our team will get back to you soon on this.',
    hi: 'इंटरेस्ट दिखाने के लिए धन्यवाद। हमारी टीम आपसे जल्द ही संपर्क करेगी।',
  },

  IF_ANY_QUERY: {
    en: 'If you have any queries you can call us',
    hi: 'किसी भी प्रकार के सवाल या सहायता के लिए आप हमें कॉल कर सकते हैं।',
  },

  // About Page

  EMPOWER_INDIAS_LOCAL: {
    en: 'Empowering India’s Local Businesses responsible for 40% of employment, and core drivers of our economy.',
    hi: 'Empowering India’s Local Businesses responsible for 40% of employment, and core drivers of our economy.',
  },
  OUR_PURPOSE: {
    en: 'Our Purpose',
    hi: 'Our Purpose',
  },
  THE_REASON_FOR_EXISTENCE: {
    en: 'Our ikigai - the reason for existence',
    hi: 'Our ikigai - the reason for existence',
  },
  JOIN_US: {
    en: 'JOIN US',
    hi: 'JOIN US',
  },
  IN_INDIA_WE_LOVELOCAL: {
    en: 'In India, We Lovelocal',
    hi: 'In India, We Lovelocal',
  },
  VALUES_THAT_DRIVE_US_TO_BRING_OUR_BEST: {
    en: 'Our Core Values',
    hi: 'Our Core Values',
  },
  WHY_WORK_WITH_US: {
    en: 'Why work with us',
    hi: 'Why work with us',
  },

  POWERED_BY_LOCAL_SHOPS: {
    en: 'Powered by local shops that you trust and love',
    hi: 'Powered by local shops that you trust and love',
  },

  WHAT_IS_LIFE_AT_LOVELOCAL: {
    en: 'Life at LoveLocal',
    hi: 'Life at LoveLocal',
  },

  OUR_INVESTORS: {
    en: 'Our Investors',
    hi: 'Our Investors',
  },

  ABOUT_TITLE: {
    en: 'About us | LoveLocal | Your Trusted Local Shops Online',
    hi: 'हमारे बारे में | लवलोक | आपका विश्वसनीय स्थानीय दुकानें ऑनलाइन',
  },

  OUR_MISSION: {
    en: 'Our Mission',
    hi: 'Our Mission',
  },

  TO_DIGITIZED_AND_ORGANIZED: {
    en: 'To digitize and organize local retailers to power the largest retail network in the world and deliver the neighborhood shopping experience of the future.',
    hi: 'To digitize and organize local retailers to power the largest retail network in the world and deliver the neighborhood shopping experience of the future.',
  },

  IN_INDIA_TO_LOCAL_STORE: {
    en: 'In India, local retailers are responsible for 40% of employment, core to our economy.',
    hi: 'In India, local retailers are responsible for 40% of employment, core to our economy.',
  },

  GROWING_LOCAL_RETAILER: {
    en: 'Growing our local retail partners is our ‘ikigai’ or reason for being.  It’s why we get out of bed every morning.',
    hi: 'Growing our local retail partners is our ‘ikigai’ or reason for being.  It’s why we get out of bed every morning.',
  },

  OUR_CORE_VALUES: {
    en: 'Our Core Values',
    hi: 'Our Core Values',
  },

  PURPOSE_DRIVEN: {
    en: 'Purpose Driven',
    hi: 'Purpose Driven',
  },

  OUR_MISSION_TO_BUILD: {
    en: 'Our mission is to build an India that benefits every Indian, by empowering local business owners. We are driven by purpose, and aim to have a huge social impact and build a very large business',
    hi: 'Our mission is to build an India that benefits every Indian, by empowering local business owners. We are driven by purpose, and aim to have a huge social impact and build a very large business',
  },

  THINK_BIG: {
    en: 'Think big. Pursue excellence',
    hi: 'Think big. Pursue excellence',
  },

  THINK_BIG_DESC: {
    en: 'Thinking big precedes great achievement. Relentless pursuit of the highest standards secures great achievement. We constantly aim to deliver better for our customers, and solve problems permanently.',
    hi: 'Thinking big precedes great achievement. Relentless pursuit of the highest standards secures great achievement. We constantly aim to deliver better for our customers, and solve problems permanently.',
  },

  CUSTOMER_ABOVE_ALL: {
    en: 'Customer, above All',
    hi: 'Customer, above All',
  },

  CUSTOMER_ABOVE_ALL_DESC: {
    en: 'We work to make our customers lives measurably better by empowering & serving them, solving key problems for them, empathy, & seamless, delightful experiences. Earn every customer’s respect and trust.',
    hi: 'We work to make our customers lives measurably better by empowering & serving them, solving key problems for them, empathy, & seamless, delightful experiences. Earn every customer’s respect and trust.',
  },

  OWNERSHIP: {
    en: 'Ownership',
    hi: 'Ownership',
  },

  OWNERSHIP_DESC: {
    en: 'Leadership is ownership. A leader is on the frontline, thinks beyond her/himself, seeks more responsibility, & never says “that’s not my job”. Every single person on our team is expected to be an owner, and therefore a leader.',
    hi: 'Leadership is ownership. A leader is on the frontline, thinks beyond her/himself, seeks more responsibility, & never says “that’s not my job”. Every single person on our team is expected to be an owner, and therefore a leader.',
  },

  MOVE_FAST_FAIL_FORWARD: {
    en: 'Move fast & Fail forward',
    hi: 'Move fast & Fail forward',
  },

  MOVE_FAST_FAIL_FORWARD_DESC: {
    en: 'To win in business you have to be fearless and fast. We expect you to fail-forward your way to success i.e. make decisions, execute fast, experiment – learn from the results, become better, do more, get faster. And repeat.',
    hi: 'To win in business you have to be fearless and fast. We expect you to fail-forward your way to success i.e. make decisions, execute fast, experiment – learn from the results, become better, do more, get faster. And repeat.',
  },

  FRUGALITY_BREEDS_INNOVATING: {
    en: 'Frugality breeds innovation, secures tomorrow',
    hi: 'Frugality breeds innovation, secures tomorrow',
  },

  FRUGALITY_BREEDS_INNOVATING_DESC: {
    en: 'We are building something to stand the test of time, not to be a shooting star here one day and gone the next. Resourcefulness, self-sufficiency, and results-driven investments impress us.',
    hi: 'We are building something to stand the test of time, not to be a shooting star here one day and gone the next. Resourcefulness, self-sufficiency, and results-driven investments impress us.',
  },

  POWER_TEAM: {
    en: 'Power of Team',
    hi: 'Power of Team',
  },

  POWER_TEAM_DESC: {
    en: 'We believe in strong, high performance teams – and not individual stars. We are at the same time pushing each other to our individual best, collaborating constantly, and supporting one another when needed.',
    hi: 'We believe in strong, high performance teams – and not individual stars. We are at the same time pushing each other to our individual best, collaborating constantly, and supporting one another when needed.',
  },

  RADICAL_HONEST: {
    en: 'Radical honesty & feedback',
    hi: 'Radical honesty & feedback',
  },

  RADICAL_HONEST_DESC: {
    en: 'We believe in open personal accountability, respectful debate & disagreement, and direct feedback. This develops you individually, and empowers us to grow as a team.',
    hi: 'We believe in open personal accountability, respectful debate & disagreement, and direct feedback. This develops you individually, and empowers us to grow as a team.',
  },

  INTEGRITY_KINDNESS: {
    en: 'Integrity & Kindness',
    hi: 'Integrity & Kindness',
  },

  INTEGRITY_KINDNESS_DESC: {
    en: 'Integrity means being trustworthy and reliable. We believe it is better to be kind than clever. We are a BS- / gossip- / personal politics- free environment. We celebrate diversity of all kinds.',
    hi: 'Integrity means being trustworthy and reliable. We believe it is better to be kind than clever. We are a BS- / gossip- / personal politics- free environment. We celebrate diversity of all kinds.',
  },

  TENACITY_GRIT: {
    en: 'Tenacity & Grit',
    hi: 'Tenacity & Grit',
  },

  TENACITY_GRIT_DESC: {
    en: 'Everything negative – challenges, pressure, stress – are an opportunity to rise. We don’t quit until the problem is solved, the goal achieved, and the customer served to the very best of our ability. We always persevere.',
    hi: 'Everything negative – challenges, pressure, stress – are an opportunity to rise. We don’t quit until the problem is solved, the goal achieved, and the customer served to the very best of our ability. We always persevere.',
  },

  DELIVERING_RESULTS: {
    en: 'Delivering results, backed by data',
    hi: 'Delivering results, backed by data',
  },

  DELIVERING_RESULTS_DESC: {
    en: 'First, we believe in measuring ourselves and that data is the backbone to driving results. Second, we measure ourselves not by the hours we work or random boxes checked, but in the results we achieve.',
    hi: 'First, we believe in measuring ourselves and that data is the backbone to driving results. Second, we measure ourselves not by the hours we work or random boxes checked, but in the results we achieve.',
  },

  ALWAYS_LEARNING: {
    en: 'Always be learning',
    hi: 'Always be learning',
  },

  ALWAYS_LEARNING_DESC: {
    en: 'Be curious, read a lot, learn from others and explore new ideas constantly. The world around us is changing at an unprecedented rate. To be leaders in this world, making good decisions, we must always be learning.',
    hi: 'Be curious, read a lot, learn from others and explore new ideas constantly. The world around us is changing at an unprecedented rate. To be leaders in this world, making good decisions, we must always be learning.',
  },

  JOIN_OUR_MISSION: {
    en: 'Join Our Mission',
    hi: 'Join Our Mission',
  },

  EMAIL_AT_US: {
    en: 'Email us at',
    hi: 'Email us at',
  },

  FUELING_OUR_MISSION: {
    en: 'Fuelling our mission to revolutionize local retail',
    hi: 'Fuelling our mission to revolutionize local retail',
  },

  WELCOMING_INVESTORS: {
    en: 'Welcoming investors: Gojek co-founders, ACV, Henkel X, Candy Ventures & Suvir Varma',
    hi: 'Welcoming investors: Gojek co-founders, ACV, Henkel X, Candy Ventures & Suvir Varma',
  },

  OUR_FONDER_VITAL_VOICES: {
    en: 'Our founder features in Vital Voices’ elite list of 100 Global Women Leaders',
    hi: 'Our founder features in Vital Voices’ elite list of 100 Global Women Leaders',
  },

  ABOUT_AK: {
    en: 'Akanksha Hazari, redefining power, with Melinda Gates, Greta Thunberg, Beyoncé & others',
    hi: 'Akanksha Hazari, redefining power, with Melinda Gates, Greta Thunberg, Beyoncé & others',
  },

  READ_MORE: {
    en: 'Read More',
    hi: 'Read More',
  },

  // AK VITAL VOICES PAGE

  AK_VITAL_VOICES_TITLE: {
    en: 'Akanksha Hazari, Founder & CEO of LoveLocal redefining power, with Melinda Gates, Greta hunberg, Beyoncé & others',
    hi: 'आकांक्षा हजारी, लवलोकल की संस्थापिका और सीईओ, मेलिंडा गेट्स, ग्रेटा हॉन्बर्ग, बेयोंसे और अन्य के साथ',
  },

  AKANKSHA_HAZARI: {
    en: 'Akanksha Hazari,',
    hi: 'Akanksha Hazari,',
  },

  CEO_AND_FOUNDER: {
    en: 'CEO & Founder of LoveLocal (formerly m.Paani)',
    hi: 'CEO & Founder of LoveLocal (formerly m.Paani)',
  },

  ABOUT_AKANKSHA_HAZARI_VITAL_VOICES: {
    en: 'Featured amongst 100 global women leaders who are redefining power, with Melinda Gates, Greta Thunberg, Beyoncé & others',
    hi: 'Featured amongst 100 global women leaders who are redefining power, with Melinda Gates, Greta Thunberg, Beyoncé & others',
  },

  VITAl_VOICE_DATE: {
    en: 'Sunday 8 March, 2020',
    hi: 'Sunday 8 March, 2020',
  },

  VITAl_VOICE_ADDRESS: {
    en: 'MUMBAI, INDIA | WASHINGTON DC, USA – Vital Voices Global Partnership,',
    hi: 'MUMBAI, INDIA | WASHINGTON DC, USA – Vital Voices Global Partnership,',
  },

  AN_INTERNATIONAL_NON_PROFIT_MSG: {
    en: 'an international non-profit that empowers and champions women leaders around the world, partnered with artist Gayle Kabaker to create a ground-breaking art exhibit: Vital Voices: 100 Women Using their Power to Empower.',
    hi: 'an international non-profit that empowers and champions women leaders around the world, partnered with artist Gayle Kabaker to create a ground-breaking art exhibit: Vital Voices: 100 Women Using their Power to Empower.',
  },

  ABOUT_AKANKSHA_HAZARI_VITAL_VOICES_DESC: {
    en: 'Akanksha Hazari, CEO & Founder of LoveLocal (formerly m.Paani), is the only Indian tech entrepreneur to be chosen and featured in this unprecedented art exhibition and companion coffee table book, celebrating 100 global women leaders who are redefining power. Akanksha will be featured in the series alongside the likes of Melinda Gates, Malala Yousafzai, Greta Thunberg, Beyoncé, Serena Williams and many other inspiring women.',
    hi: 'Akanksha Hazari, CEO & Founder of LoveLocal (formerly m.Paani), is the only Indian tech entrepreneur to be chosen and featured in this unprecedented art exhibition and companion coffee table book, celebrating 100 global women leaders who are redefining power. Akanksha will be featured in the series alongside the likes of Melinda Gates, Malala Yousafzai, Greta Thunberg, Beyoncé, Serena Williams and many other inspiring women.',
  },

  VITAl_VOICE_100_WOMEN: {
    en: 'Vital Voices: 100 Women Using Their Power to Empower –',
    hi: 'Vital Voices: 100 Women Using Their Power to Empower –',
  },

  IN_CONVERSATION_WITH: {
    en: 'in conversation with ',
    hi: 'in conversation with ',
  },

  FEATURING_PAINTING: {
    en: 'Alyse Nelson featuring paintings by Gayle Kabaker – ',
    hi: 'Alyse Nelson featuring paintings by Gayle Kabaker – ',
  },

  SELECTION_OF_ORIGINAL_PORTRAIT: {
    en: 'will feature a selection of original portraits, accompanied by thought-provoking excerpts of the first-person narratives included in the book. Candid and compelling, each leader shares personal stories, insights and ideas, showing us that women lead differently and that this difference is sorely needed in our world today. While each woman is path breaking in her own right, it’s together that these 100 voices illustrate the transformative power of women’s leadership across cultures, industries, and generations.',
    hi: 'will feature a selection of original portraits, accompanied by thought-provoking excerpts of the first-person narratives included in the book. Candid and compelling, each leader shares personal stories, insights and ideas, showing us that women lead differently and that this difference is sorely needed in our world today. While each woman is path breaking in her own right, it’s together that these 100 voices illustrate the transformative power of women’s leadership across cultures, industries, and generations.',
  },

  AK_SHARES: {
    en: 'Akanksha Hazari shares,',
    hi: 'Akanksha Hazari shares,',
  },

  I_FEEL_INCREDIBLE_HUMBLE: {
    en: '“I feel incredibly humbled to be a part of such an amazing group of women, who inspire me and teach me. Women stand on the front line of critical change the world needs today. They make me bullish about the future.”',
    hi: '“I feel incredibly humbled to be a part of such an amazing group of women, who inspire me and teach me. Women stand on the front line of critical change the world needs today. They make me bullish about the future.”',
  },

  LOVELOCAL_NAME: {
    en: 'LoveLocal (formerly m.Paani)',
    hi: 'LoveLocal (formerly m.Paani)',
  },

  LEADING_THE_REVOLUTION: {
    en: 'is leading the revolution to empower India’s 60 million+ local retailers by digitizing & organizing them to deliver the neighborhood shopping experience of the future.',
    hi: 'is leading the revolution to empower India’s 60 million+ local retailers by digitizing & organizing them to deliver the neighborhood shopping experience of the future.',
  },

  AK_VITAL_MSG: {
    en: 'adds: “Local Retailers are the foundation of a thriving community and India’s economy. Small businesses account for almost 40% of the country’s employment. LoveLocal (formerly m.Paani) is enabling local shops to go online, digitize their consumer relationships, access business data and insights, and manage their business operations more effectively. We empower local retailers to thrive in a rapidly changing world.”',
    hi: 'adds: “Local Retailers are the foundation of a thriving community and India’s economy. Small businesses account for almost 40% of the country’s employment. LoveLocal (formerly m.Paani) is enabling local shops to go online, digitize their consumer relationships, access business data and insights, and manage their business operations more effectively. We empower local retailers to thrive in a rapidly changing world.”',
  },

  AT_21_AK_WAS: {
    en: 'At 21, Akanksha Hazari was a founding partner of Young Professionals in Foreign Policy,',
    hi: 'At 21, Akanksha Hazari was a founding partner of Young Professionals in Foreign Policy,',
  },

  ABOUT_AK_WAS_MSG: {
    en: `the world’s first policy think tank for young people;
        today YPFP serves young people in 9 countries and boasts
        a global membership. She also led 5 major economic initiatives
        in Gaza and the West Bank, including a $200m loan guarantee
        facility to support Palestinian owned SMEs and led a pan-India
        agritech project where she spent a year living in villages across
        India looking at how mobile technology can be used to increase farmer income.
        Akanksha is a Princeton University and Cambridge University alumnus,
        Ashoka Fellow, and former competitive squash player ranked in the top 20
        juniors internationally and is a 2-time HK & Indian national champion,
        among winning multiple other international titles. She speaks 6 languages.
        Akanksha won the $1m Hult Prize 2011 awarded by President Bill Clinton,
        and received the Vital Voices Global Economic Empowerment Award 2016
        from Secretaries of State Hillary Rodham Clinton and Madeline Albright,
        and is recognized in US Senate Resolution 418.`,
    hi: `the world’s first policy think tank for young people;
        today YPFP serves young people in 9 countries and boasts
        a global membership. She also led 5 major economic initiatives
        in Gaza and the West Bank, including a $200m loan guarantee
        facility to support Palestinian owned SMEs and led a pan-India
        agritech project where she spent a year living in villages across
        India looking at how mobile technology can be used to increase farmer income.
        Akanksha is a Princeton University and Cambridge University alumnus,
        Ashoka Fellow, and former competitive squash player ranked in the top 20
        juniors internationally and is a 2-time HK & Indian national champion,
        among winning multiple other international titles. She speaks 6 languages.
        Akanksha won the $1m Hult Prize 2011 awarded by President Bill Clinton,
        and received the Vital Voices Global Economic Empowerment Award 2016
        from Secretaries of State Hillary Rodham Clinton and Madeline Albright,
        and is recognized in US Senate Resolution 418.`,
  },

  OTHER_NOTABLE_WOMEN: {
    en: `Other notable women included in the select 100
        leaders featured in the exhibit and companion
        book are, Ruth Bader Ginsburg, US Supreme Court Justice,
        Rihanna, and Nancy Pelosi, Speaker of the US Speaker of
        the United States House of Representatives, amongst others.`,
    hi: `Other notable women included in the select 100
        leaders featured in the exhibit and companion
        book are, Ruth Bader Ginsburg, US Supreme Court Justice,
        Rihanna, and Nancy Pelosi, Speaker of the US Speaker of
        the United States House of Representatives, amongst others.`,
  },

  VITAL_VOICES_WORKS: {
    en: `Vital Voices has worked with over 18,000 women leaders
        across 182 countries and territories for the past 20 years.
        From that experience, the organization understands that women
        lead differently; the thousands of women leaders Vital Voices has partnered
        with have been resolute in their sense of purpose and
        commitment towards making positive change.`,
    hi: `Vital Voices has worked with over 18,000 women leaders
        across 182 countries and territories for the past 20 years.
        From that experience, the organization understands that women
        lead differently; the thousands of women leaders Vital Voices has partnered
        with have been resolute in their sense of purpose and
        commitment towards making positive change.`,
  },

  MARKS_A_SIGNIFICANT_YEAR: {
    en: `“2020 marks a significant year for women,
          with the centennial of women’s suffrage in the United States
          and the 25th anniversary of the historic United Nations
          Fourth World Conference on Women. It is a critical year
          for taking stock of all that’s been achieved in the global movement
          for gender equality,” said Alyse Nelson, President,
          and CEO of Vital Voices. “As we look to the future,
          and the great unfinished business that remains,
          we turn to 100 of the most daring and creative leaders of our time,
          who share their vision and solutions in
          Vital Voices: 100 Women Using Their Power to Empower.”`,
    hi: `“2020 marks a significant year for women,
          with the centennial of women’s suffrage in the United States
          and the 25th anniversary of the historic United Nations
          Fourth World Conference on Women. It is a critical year
          for taking stock of all that’s been achieved in the global movement
          for gender equality,” said Alyse Nelson, President,
          and CEO of Vital Voices. “As we look to the future,
          and the great unfinished business that remains,
          we turn to 100 of the most daring and creative leaders of our time,
          who share their vision and solutions in
          Vital Voices: 100 Women Using Their Power to Empower.”`,
  },

  EACH_PAINTING_IN_THE_SERIES: {
    en: `Each painting in the series is being produced by Gayle Kabaker.
        Kabaker is an award-winning illustrator and writer living
        in Western Massachusetts whose work has been used for everything
        from fashion and general illustration, to animation, web design,
        logo and brand development. She had her first New Yorker magazine cover
        ‘June Brides’ celebrating gay marriage in 2012, and has had a total
        of five covers since then. Her work tends to be very feminine,
        exploring beauty in all forms.`,
    hi: `Each painting in the series is being produced by Gayle Kabaker.
        Kabaker is an award-winning illustrator and writer living
        in Western Massachusetts whose work has been used for everything
        from fashion and general illustration, to animation, web design,
        logo and brand development. She had her first New Yorker magazine cover
        ‘June Brides’ celebrating gay marriage in 2012, and has had a total
        of five covers since then. Her work tends to be very feminine,
        exploring beauty in all forms.`,
  },

  PAINTING_SUCH_A_WIDE_RANGE: {
    en: `“Painting such a wide range of women of all ages,
          from all over the world and ‘getting to know’
          each of these amazing, strong, brave women by
          reading about them and studying their faces carefully
          from photos in order to catch their likeness in a
          painting, has felt like such a privilege,”
          said Kabaker about the exhibit.
          “It’s been quite an education.
          Sometimes uplifting and exciting, other times,
          really painful to realize the tragedies in these women
          lives that led them to become activists.
          I am so lucky to have an amazing creative collaborator
          in Vital Voices President, CEO and co-founder Alyse Nelson,
          who gave me lots of creative freedom to let each painting dictate
          what felt right. This has been one of the most rewarding projects
          I’ve ever worked on.”`,
    hi: `“Painting such a wide range of women of all ages,
          from all over the world and ‘getting to know’
          each of these amazing, strong, brave women by
          reading about them and studying their faces carefully
          from photos in order to catch their likeness in a
          painting, has felt like such a privilege,”
          said Kabaker about the exhibit.
          “It’s been quite an education.
          Sometimes uplifting and exciting, other times,
          really painful to realize the tragedies in these women
          lives that led them to become activists.
          I am so lucky to have an amazing creative collaborator
          in Vital Voices President, CEO and co-founder Alyse Nelson,
          who gave me lots of creative freedom to let each painting dictate
          what felt right. This has been one of the most rewarding projects
          I’ve ever worked on.”`,
  },

  THE_EXHIBIT_WILL_DOUBT: {
    en: `The exhibit will debut on International Women’s Day (March 8, 2020)
        and will be on display in the Hall of Nations at the John F. Kennedy Center
        for the Performing Arts through March 21, 2020, as a part of DIRECT CURRENT,
        the Center’s two-week contemporary culture immersion.`,
    hi: `The exhibit will debut on International Women’s Day (March 8, 2020)
        and will be on display in the Hall of Nations at the John F. Kennedy Center
        for the Performing Arts through March 21, 2020, as a part of DIRECT CURRENT,
        the Center’s two-week contemporary culture immersion.`,
  },

  MEDIA_CONTACT: {
    en: 'Media Contact:',
    hi: 'Media Contact:',
  },

  ABOUT_LOVELOCAL: {
    en: 'About LoveLocal (formerly m.Paani)',
    hi: 'About LoveLocal (formerly m.Paani)',
  },

  LOVELOCAL_IS_DIGITIZING: {
    en: `LoveLocal (formerly m.Paani) is digitizing and organizing local retailers,
        to power the largest retail network in the world,
        and deliver the neighborhood shopping experience of the future.
        LoveLocal (formerly m.Paani) is based in Mumbai, India. For more information,
        please visit: `,
    hi: `LoveLocal (formerly m.Paani) is digitizing and organizing local retailers,
        to power the largest retail network in the world,
        and deliver the neighborhood shopping experience of the future.
        LoveLocal (formerly m.Paani) is based in Mumbai, India. For more information,
        please visit: `,
  },

  ABOUT_VITAL_VOICES: {
    en: 'About Vital Voices',
    hi: 'About Vital Voices',
  },

  ABOUT_VITAL_VOICES_DESC: {
    en: `Vital Voices Global Partnership identifies,
          provides training for and invests in creative and
          fearless women leaders creating change in their communities
          and around the world. Guided by the belief that women are essential
          to progress in their communities, Vital Voices searches all over
          the world for women leaders with a daring vision to partner with
          to make that vision a reality through grants, skill-building training,
          network expansion, mentorship and guidance to accelerate change
          on a global scale. Visit www.vitalvoices.org to learn more.`,
    hi: `Vital Voices Global Partnership identifies,
          provides training for and invests in creative and
          fearless women leaders creating change in their communities
          and around the world. Guided by the belief that women are essential
          to progress in their communities, Vital Voices searches all over
          the world for women leaders with a daring vision to partner with
          to make that vision a reality through grants, skill-building training,
          network expansion, mentorship and guidance to accelerate change
          on a global scale. Visit www.vitalvoices.org to learn more.`,
  },

  ABOUT_GAYLE: {
    en: 'About Gayle Kabaker',
    hi: 'About Gayle Kabaker',
  },

  ABOUT_GAYLE_DESC: {
    en: `Kabaker is an award-winning illustrator and writer living in Western Massachusetts
        whose work has been used for everything from fashion and general illustration,
        to animation, web design,
        logo and brand development.
        She had her first New Yorker magazine cover
        ‘June Brides’ celebrating gay marriage in 2012 and has had a total
        of five covers since then.
        Her work tends to be very feminine, exploring beauty in all forms.`,
    hi: `Kabaker is an award-winning illustrator and writer living in Western Massachusetts
        whose work has been used for everything from fashion and general illustration,
        to animation, web design,
        logo and brand development.
        She had her first New Yorker magazine cover
        ‘June Brides’ celebrating gay marriage in 2012 and has had a total
        of five covers since then.
        Her work tends to be very feminine, exploring beauty in all forms.`,
  },

  // CEO LETTER

  CEO_LETTER_TITLE: {
    en: 'A message from Akanksha Hazari, Founder & CEO of LoveLocal | LoveLocal | Your Trusted Local Shops Online',
    hi: 'आकांक्षा हजारी का एक संदेश, लवलोकल के संस्थापक और सीईओ | लवलोकल | आपका विश्वसनीय स्थानीय दुकानें ऑनलाइन',
  },

  FOUNDER_MESSAGE: {
    en: 'A message from Akanksha Hazari, Founder & CEO of LoveLocal',
    hi: 'A message from Akanksha Hazari, Founder & CEO of LoveLocal',
  },

  HI_EVERYONE: {
    en: 'Hi Everyone!',
    hi: 'Hi Everyone!',
  },

  I_HOPE_ALL_SAFE: {
    en: 'I hope you all are safe and well, following the important guidance to stay home and socially distance. I would also like to express our deepest thanks to all those serving on the frontlines of this crisis, doctors, police, warehouse and operations teams, deliver partners, and our local essentials shops, and many more.',
    hi: 'I hope you all are safe and well, following the important guidance to stay home and socially distance. I would also like to express our deepest thanks to all those serving on the frontlines of this crisis, doctors, police, warehouse and operations teams, deliver partners, and our local essentials shops, and many more.',
  },

  EXCITING_ANNOUNCEMENT: {
    en: 'I have an exciting announcement to share with you, in these otherwise challenging times — we are changing our name! From today, m.Paani will rebranded to LoveLocal.',
    hi: 'I have an exciting announcement to share with you, in these otherwise challenging times — we are changing our name! From today, m.Paani will rebranded to LoveLocal.',
  },

  WORKING_FOR_MONTHS: {
    en: 'We have been working on this for many months and didn’t expect to be launching in this context.  But also feel that - in a time where the local essentials shops, our neighborhood kirana, grocer, chemist, and fruits and vegetables shops are on the frontline of this fight - the need for this change is more important and significant than ever.',
    hi: 'We have been working on this for many months and didn’t expect to be launching in this context.  But also feel that - in a time where the local essentials shops, our neighborhood kirana, grocer, chemist, and fruits and vegetables shops are on the frontline of this fight - the need for this change is more important and significant than ever.',
  },

  WHY_LOVELOCAL: {
    en: 'So why LoveLocal?',
    hi: 'So why LoveLocal?',
  },

  WHY_LOVELOCAL_DESC: {
    en: 'We are bringing your trusted local daily needs shops online.  Those local shops that have served us for decades.  For us, LoveLocal is not just a brand, but the beginning of a movement to – LoveLocal.  As Indians, we have always had a special relationship with our local shops.',
    hi: 'We are bringing your trusted local daily needs shops online.  Those local shops that have served us for decades.  For us, LoveLocal is not just a brand, but the beginning of a movement to – LoveLocal.  As Indians, we have always had a special relationship with our local shops.',
  },

  LOVELOCAL_EMPOWERS: {
    en: 'LoveLocal empowers you to:',
    hi: 'LoveLocal empowers you to:',
  },

  DISCOVER_LOVELOCAL_SHOP: {
    en: 'Discover all the local daily needs shops, products and amazing offers and deals in your area',
    hi: 'Discover all the local daily needs shops, products and amazing offers and deals in your area',
  },

  ORDER_ONLINE_FROM_LOVELOCAL: {
    en: 'Order online from your local shops',
    hi: 'Order online from your local shops',
  },

  GET_DELIVERY_FASTEST: {
    en: 'Get the fastest home delivery',
    hi: 'Get the fastest home delivery',
  },

  BROWSE_LARGEST_SELECTION: {
    en: 'Browse the largest selection of daily needs products online with over 4L+ products to choose from',
    hi: 'Browse the largest selection of daily needs products online with over 4L+ products to choose from',
  },

  BENEFITS_AMAZING: {
    en: 'Benefits from the amazing service and quality guaranteed by our trusted local shops',
    hi: 'Benefits from the amazing service and quality guaranteed by our trusted local shops',
  },

  FOR_RETAILERS_PARTNER: {
    en: 'For our retail partners, we choose LoveLocal because it puts our promise and commitment to you — local shopkeepers — in our name itself.',
    hi: 'For our retail partners, we choose LoveLocal because it puts our promise and commitment to you — local shopkeepers — in our name itself.',
  },

  MISSION_TO_PROVIDE_LOVELOCAL: {
    en: 'This is how seriously we take our mission to provide local shops with all the tools and support you need to go online, digitize and grow your business. LoveLocal is a name that you as a local shop owner can feel ownership of and proud of – it is your business, your dream, and this is your platform.',
    hi: 'This is how seriously we take our mission to provide local shops with all the tools and support you need to go online, digitize and grow your business. LoveLocal is a name that you as a local shop owner can feel ownership of and proud of – it is your business, your dream, and this is your platform.',
  },

  FOR_THE_BRAND_WE_HAVE_CHOSEN: {
    en: 'For the brand, we have chosen the bright Indian pink, yellow and blue colours to represent our vibrant and warm Indian culture that we are so proud of – and bring emotion to technology.',
    hi: 'For the brand, we have chosen the bright Indian pink, yellow and blue colours to represent our vibrant and warm Indian culture that we are so proud of – and bring emotion to technology.',
  },

  SHOPS_WE_HAVE: {
    en: 'Today we have nearly 80,000 LoveLocal shops serving more than 10 million consumers across India.',
    hi: 'Today we have nearly 80,000 LoveLocal shops serving more than 10 million consumers across India.',
  },

  SHOPS_WE_HAVE_DESC: {
    en: 'At this critical time we are working hard to get essentials supplies to our LoveLocal shops and consumers. We have launched No Touch Delivery and No Touch Shop Pickup for your safety. Download the LoveLocal app or visit lovelocal.in to start ordering from your trusted local shops online now.',
    hi: 'At this critical time we are working hard to get essentials supplies to our LoveLocal shops and consumers. We have launched No Touch Delivery and No Touch Shop Pickup for your safety. Download the LoveLocal app or visit lovelocal.in to start ordering from your trusted local shops online now.',
  },

  WE_ALL_OFFERING_TO_LOVELOCAL_SHOPS: {
    en: 'We are also offering the LoveLocal platform completely free to all retailers in the essentials category across India indefinitely for the duration of this crisis, to support the provision of essentials goods to consumers everywhere.',
    hi: 'We are also offering the LoveLocal platform completely free to all retailers in the essentials category across India indefinitely for the duration of this crisis, to support the provision of essentials goods to consumers everywhere.',
  },

  IF_YOU_ARE_A_RETAILER: {
    en: 'If you are a retailer and want to become a LoveLocal Shop, please download the LoveLocal Retail Partner App.',
    hi: 'If you are a retailer and want to become a LoveLocal Shop, please download the LoveLocal Retail Partner App.',
  },

  FROM_THE_TEAM: {
    en: 'From the whole LoveLocal team, thank you for the opportunity to serve you.',
    hi: 'From the whole LoveLocal team, thank you for the opportunity to serve you.',
  },

  WILL_BEAT_PANDEMIC: {
    en: 'We will beat this pandemic.',
    hi: 'We will beat this pandemic.',
  },

  STAY_HOME_STAY_SAFE: {
    en: 'Stay home, stay healthy and LoveLocal!',
    hi: 'Stay home, stay healthy and LoveLocal!',
  },

  // Mpaani Funding

  MPAANI_FUNDING_TITLE: {
    en: 'LoveLocal (formerly m.Paani) raises $5.5million in Series A led by AC Ventures, Henkel X, Candy Ventures, Gojek co-founders and others.',
    hi: 'लवलोकल (पूर्व में m.Paani) AC वेंचर्स, हेंकेल एक्स, कैंडी वेंचर्स, गोजेक सह-संस्थापकों और अन्य के नेतृत्व में श्रृंखला ए में $ 5.5 मिलियन का निवेश करती है।',
  },

  INDIA_LEADING_LOCAL_RETAILER: {
    en: `LoveLocal (formerly m.Paani), India’s leading local retailer digitization
          platform, has raised USD $5.5m or INR 39 crores, in a Series A investment
          round led by AC Ventures, Henkel,
          Candy Ventures, in addition to noteworthy angel investors including Kevin Aluwi,
          CEO and Co-Founder of Gojek and Suvir Varma,
          Board Member and Senior Advisor at Bain & Company, Private Equity.
          Existing investors Chiratae Ventures and
          Blume Ventures also participated in the round.`,
    hi: `LoveLocal (formerly m.Paani), India’s leading local retailer digitization
          platform, has raised USD $5.5m or INR 39 crores, in a Series A investment
          round led by AC Ventures, Henkel,
          Candy Ventures, in addition to noteworthy angel investors including Kevin Aluwi,
          CEO and Co-Founder of Gojek and Suvir Varma,
          Board Member and Senior Advisor at Bain & Company, Private Equity.
          Existing investors Chiratae Ventures and
          Blume Ventures also participated in the round.`,
  },

  A_GOOGLE_LAUNCHPAD_AND_GOOGLE: {
    en: `A Google Launchpad and Google Studio company,
        LoveLocal (formerly m.Paani) is digitizing and organizing local retailers
        to deliver the neighbourhood shopping experience of the future.
        It will use the funds to invest in product development,
        accelerated growth and expansion, and key hires.`,
    hi: `A Google Launchpad and Google Studio company,
        LoveLocal (formerly m.Paani) is digitizing and organizing local retailers
        to deliver the neighbourhood shopping experience of the future.
        It will use the funds to invest in product development,
        accelerated growth and expansion, and key hires.`,
  },

  PRESS_COVERAGE: {
    en: 'Press Coverage',
    hi: 'Press Coverage',
  },

  AK_SAYS: {
    en: 'Akanksha Hazari-Ericson, CEO and Founder of LoveLocal (formerly m.Paani), says,',
    hi: 'Akanksha Hazari-Ericson, CEO and Founder of LoveLocal (formerly m.Paani), says,',
  },

  AK_SAYS_MSG: {
    en: `“We have always believed in the local retailer.
          They win on every consumer consideration: service, quality,
          product selection, value, credit and delivery speed.
          Small businesses also account for more than 40% of employment.
          They are a bedrock of communities and the economy.
          LoveLocal (formerly m.Paani) is bridging the technology gap to ensure that our
          local retailers not just survive but thrive in India 2.0.`,
    hi: `“We have always believed in the local retailer.
          They win on every consumer consideration: service, quality,
          product selection, value, credit and delivery speed.
          Small businesses also account for more than 40% of employment.
          They are a bedrock of communities and the economy.
          LoveLocal (formerly m.Paani) is bridging the technology gap to ensure that our
          local retailers not just survive but thrive in India 2.0.`,
  },

  A_FUTURE_FOR_LOCAL_RETAILERS: {
    en: 'The future of Indian retail is, and must be, local.” ',
    hi: 'The future of Indian retail is, and must be, local.” ',
  },

  A_SUCCESSFULLY_SECOND: {
    en: 'A successful second time entrepreneur, Akanksha is a Princeton University and Cambridge University alumnus.',
    hi: 'A successful second time entrepreneur, Akanksha is a Princeton University and Cambridge University alumnus.',
  },

  EXECUTIVE_DIRECTOR: {
    en: 'Jesús Eduardo García Chapa, Executive Director of Venture Capital, AC Ventures: ',
    hi: 'Jesús Eduardo García Chapa, Executive Director of Venture Capital, AC Ventures: ',
  },

  WE_ARE_VERY_HAPPY: {
    en: `“We are very happy to be a part of LoveLocal’s (formerly m.Paani) journey.
          Their vision to foster local retailers is something
          we share and are absolutely convinced is a game changer
          for the traditional trade channel and the retail industry.”`,
    hi: `“We are very happy to be a part of LoveLocal’s (formerly m.Paani) journey.
          Their vision to foster local retailers is something
          we share and are absolutely convinced is a game changer
          for the traditional trade channel and the retail industry.”`,
  },

  INDIAN_RETAIL_POWER: {
    en: `Indian retail is powered by more than 60 million local businesses,
        that represent a $1.1trillion informal market or more than 95% of total retail.
        Despite the launch of e-commerce and new big box retail options,
        this unorganized market has more than doubled in the past 5 years,
        and 86% of the average Indian consumer’s shopping continues to happen
        within 3-4kms of home, at local neighbourhood shops.
        As over a billion Indian consumers quickly come online,
        these local shops – the vast majority of which are not digitized in
        any way whatsoever yet – must transform to serve
        this new digitally-driven shopper.`,
    hi: `Indian retail is powered by more than 60 million local businesses,
        that represent a $1.1trillion informal market or more than 95% of total retail.
        Despite the launch of e-commerce and new big box retail options,
        this unorganized market has more than doubled in the past 5 years,
        and 86% of the average Indian consumer’s shopping continues to happen
        within 3-4kms of home, at local neighbourhood shops.
        As over a billion Indian consumers quickly come online,
        these local shops – the vast majority of which are not digitized in
        any way whatsoever yet – must transform to serve
        this new digitally-driven shopper.`,
  },

  HAS_MORE_THAN_50000_RETAILERS: {
    en: `Today, LoveLocal (formerly m.Paani) has more than 50,000
        retailers and 7 million consumers on its platform.
        Growing at 30%+ per month, this lean 25-member team
        has managed to rapidly scale organically, with no physical
        sales teams, incentives nor meaningful marketing investments so far.
        Most notably, LoveLocal (formerly m.Paani) has proven monetization
        via retailer subscriptions, delivering business growth of more than
        25% to its most engaged retail partners. As one LoveLocal (formerly m.Paani)
        partner shares, “We are a local retail pharmacy chain and we realized that
        competition from online players was affecting our business a lot.
        Thanks to LoveLocal (formerly m.Paani), in last 2 years,
        our customer base has grown by 73% and repeat shopper base has grown by 51%.”`,
    hi: `Today, LoveLocal (formerly m.Paani) has more than 50,000
        retailers and 7 million consumers on its platform.
        Growing at 30%+ per month, this lean 25-member team
        has managed to rapidly scale organically, with no physical
        sales teams, incentives nor meaningful marketing investments so far.
        Most notably, LoveLocal (formerly m.Paani) has proven monetization
        via retailer subscriptions, delivering business growth of more than
        25% to its most engaged retail partners. As one LoveLocal (formerly m.Paani)
        partner shares, “We are a local retail pharmacy chain and we realized that
        competition from online players was affecting our business a lot.
        Thanks to LoveLocal (formerly m.Paani), in last 2 years,
        our customer base has grown by 73% and repeat shopper base has grown by 51%.”`,
  },

  YOUR_LOCAL_ONLINE_SHOP: {
    en: 'LoveLocal’s retailer product – ‘Aapki Apni Digital Dukaan’ or ‘Your Online Local Shop’',
    hi: 'LoveLocal’s retailer product – ‘Aapki Apni Digital Dukaan’ or ‘Your Online Local Shop’',
  },

  FULL_SUIT_SOLUTION: {
    en: ` – is a full suite solution enabling local shops to go online,
          digitize their consumer relationships, access business data and insights,
          and manage their business operations more effectively.
          Focused on the daily needs categories e.g. grocery,
          chemist, fresh fruits & vegetables, dairy and more – consumers now have their
          entire neighbourhood in the palm of their hand.
          They can discover their local shops,
          products and offers online – order online by creating a single shopping
          list across all their daily needs or shop directly online from their
          favorite local shops – in addition to enjoying an enhanced offline shopping
          experience via in-store features.`,
    hi: ` – is a full suite solution enabling local shops to go online,
          digitize their consumer relationships, access business data and insights,
          and manage their business operations more effectively.
          Focused on the daily needs categories e.g. grocery,
          chemist, fresh fruits & vegetables, dairy and more – consumers now have their
          entire neighbourhood in the palm of their hand.
          They can discover their local shops,
          products and offers online – order online by creating a single shopping
          list across all their daily needs or shop directly online from their
          favorite local shops – in addition to enjoying an enhanced offline shopping
          experience via in-store features.`,
  },

  ABOUT_KELVIN: {
    en: 'Kevin Aluwi, CEO and Co-Founder, Gojek: ',
    hi: 'Kevin Aluwi, CEO and Co-Founder, Gojek: ',
  },

  VERY_STRONG_TEAM: {
    en: `“LoveLocal (formerly m.Paani) has a very strong team,
          and a deep understanding of the local retailer
          reflected in a great product and sound strategy for solving
          for this important user’s problems and aspirations.
          I am very excited to be a part of LoveLocal’s (formerly m.Paani)
          journey and also see huge potential for them to
          empower local retailers beyond India.”`,
    hi: `“LoveLocal (formerly m.Paani) has a very strong team,
          and a deep understanding of the local retailer
          reflected in a great product and sound strategy for solving
          for this important user’s problems and aspirations.
          I am very excited to be a part of LoveLocal’s (formerly m.Paani)
          journey and also see huge potential for them to
          empower local retailers beyond India.”`,
  },

  ITS_EXITING_TO_SEE: {
    en: '“It’s exciting to see incredible entrepreneurs grow their startups” said ',
    hi: '“It’s exciting to see incredible entrepreneurs grow their startups” said ',
  },

  ABOUT_DAVID: {
    en: 'David McLaughlin, Director, Google Developer Ecosystem, Google. ',
    hi: 'David McLaughlin, Director, Google Developer Ecosystem, Google. ',
  },

  TAKE_THE_NEXT_STEP: {
    en: `“To see Akanksha and LoveLocal (formerly m.Paani) take the next step in their
          journey alongside the Gojek founders she met
          in our accelerator showcases one of the many
          benefits for startups that take part in our program.”`,
    hi: `“To see Akanksha and LoveLocal (formerly m.Paani) take the next step in their
          journey alongside the Gojek founders she met
          in our accelerator showcases one of the many
          benefits for startups that take part in our program.”`,
  },

  ABOUT_DR_RAHMYN: {
    en: 'Dr. Rahmyn Kress and Marius Swart, heads of Henkel’s venturing and open innovation platform Henkel X Ventures: ',
    hi: 'Dr. Rahmyn Kress and Marius Swart, heads of Henkel’s venturing and open innovation platform Henkel X Ventures: ',
  },

  THE_INDIAN_RETAIL_MARKET: {
    en: `“The Indian retail market provides huge potential for Henkel’s
          business which can be unlocked by innovative digital
          go-to-market models. LoveLocal (formerly m.Paani) has developed a powerful
          mobile platform that connects consumers and local retailers and has already proven
          the capabilities of its business model. We are excited about this partnership
          through which we aim to gain valuable insights about the local retail market
          while evaluating the potential of the platform and technology
          for our other global consumer markets.”`,
    hi: `“The Indian retail market provides huge potential for Henkel’s
          business which can be unlocked by innovative digital
          go-to-market models. LoveLocal (formerly m.Paani) has developed a powerful
          mobile platform that connects consumers and local retailers and has already proven
          the capabilities of its business model. We are excited about this partnership
          through which we aim to gain valuable insights about the local retail market
          while evaluating the potential of the platform and technology
          for our other global consumer markets.”`,
  },

  ECONOMIC_EMPOWERMENT: {
    en: `Founder, Akanksha received the Vital Voices Global Leadership Award
        for Economic Empowerment 2016 from Secretaries of State
        Hillary Rodham Clinton and Madeline Albright,
        recognized in US Senate Resolution 418.`,
    hi: `Founder, Akanksha received the Vital Voices Global Leadership Award
        for Economic Empowerment 2016 from Secretaries of State
        Hillary Rodham Clinton and Madeline Albright,
        recognized in US Senate Resolution 418.`,
  },

  ABOUT_ALYSE: {
    en: 'Alyse Nelson, President and CEO, of Vital Voices ',
    hi: 'Alyse Nelson, President and CEO, of Vital Voices ',
  },

  APPROACH_TO_CRATING: {
    en: `shares: “Akanksha’s bold approach to creating social change
        in India demonstrates the types of innovative solutions we
        need to truly make sustainable progress.
        Vital Voices is privileged to partner with
        Akanksha as she continues to prove and
        scale her model to create a better world for us all.”`,
    hi: `shares: “Akanksha’s bold approach to creating social change
        in India demonstrates the types of innovative solutions we
        need to truly make sustainable progress.
        Vital Voices is privileged to partner with
        Akanksha as she continues to prove and
        scale her model to create a better world for us all.”`,
  },

  // LoveLocal Shop Req. Form

  NAME: {
    en: 'Your Name',
    hi: 'आपका नाम',
  },

  MOBILE_NUMBER: {
    en: 'Mobile Number',
    hi: 'मोबाइल नंबर',
  },

  CITY: {
    en: 'City',
    hi: 'शहर',
  },

  SUBMIT: {
    en: 'SUBMIT',
    hi: 'जमा करें',
  },

  THANK_YOU_FOR_MSG: {
    en: 'Thank you for your message. It has been sent.',
    hi: 'आपके संदेश के लिए धन्यवाद। इसे भेजा गया है।',
  },

  OOPS_PLEASE_TRY_AGAIN: {
    en: 'Something went wrong. Please try again.',
    hi: 'कुछ गलत हो गया। कृपया पुन: प्रयास करें।',
  },

  NAME_FIELD_EMPTY_ERROR: {
    en: 'Your name is required*',
    hi: 'आपका नाम आवश्यक है*',
  },

  MOBILE_FIELD_EMPTY_ERROR: {
    en: 'Mobile number is required*',
    hi: 'मोबाइल नंबर आवश्यक है*',
  },

  INVALID_MOBILE_ERROR: {
    en: 'Mobile Number is invalid',
    hi: 'मोबाइल नंबर अमान्य है',
  },

  CITY_FIELD_EMPTY_ERROR: {
    en: 'City is required*',
    hi: 'शहर आवश्यक है*',
  },

  // Footer

  ADDRESS_LINE_1: {
    en: 'WeWork, 1st Floor, Commerz II',
    hi: 'वेवर्क, पहली मंजिल, कॉमर्ज दो',
  },

  ADDRESS_LINE_2: {
    en: 'Oberoi Garden City, Goregaon East',
    hi: 'ओबेरॉय गार्डन सिटी, गोरेगांव पूर्व',
  },

  ADDRESS_LINE_3: {
    en: 'Mumbai, MH-400063',
    hi: 'मुंबई, एमएच-४०००६३',
  },

  LEGAL: {
    en: 'Legal',
    hi: 'कानूनी',
  },

  FOLLOW_US: {
    en: 'Follow Us',
    hi: 'फॉलो अस',
  },

  FOOTER_LOVELOCAL: {
    en: 'LOVELOCAL',
    hi: 'लवलोकल',
  },

  INSTAGRAM: {
    en: 'Instagram',
    hi: 'इंस्टाग्राम',
  },

  ABOUT_US: {
    en: 'About Us',
    hi: 'हमारे बारे में',
  },

  PARTNER_WITH_US: {
    en: 'Partner with us',
    hi: 'हमारे भागीदार बनें',
  },

  SHOP_FROM_LOVELOCAL: {
    en: 'Shop from LoveLocal',
    hi: 'लवलोकल से खरीदारी करें',
  },

  CONTACT_US: {
    en: 'Contact Us',
    hi: 'संपर्क करें',
  },

  FOR_CAREERS: {
    en: 'For Careers',
    hi: 'करियर के लिए',
  },
  FOR_PARTNERSHIP: {
    en: 'For Partnership',
    hi: 'साझेदारी के लिए',
  },

  FACEBOOK: {
    en: 'Facebook',
    hi: 'फेसबुक',
  },

  TWITTER: {
    en: 'Twitter',
    hi: 'ट्विटर',
  },

  LINKEDIN: {
    en: 'Linkedin',
    hi: 'लिंक्डइन',
  },

  YOUTUBE: {
    en: 'YouTube',
    hi: 'यूट्यूब',
  },

  PRIVACY_POLICY: {
    en: 'Privacy Policy',
    hi: 'प्रिवेसी पॉलिसी',
  },

  TERMS_CONDITION: {
    en: 'Terms & Condition',
    hi: 'नियम व शर्तें',
  },

};

export default String;
