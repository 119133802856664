import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoveLocal } from '../../utilities/partner/content';
import String from '../../utilities/partner/Constants';

const FooterMobile = ({ pinkLogo, language }) => (
  <Container>
    <Row
      className="text-white fs-5 justify-content-between flex-row"
    >
      <Col
        xs={8}
        className={pinkLogo ? 'px-0' : ''}
      >
        <a
          {...LoveLocal.lovelocal}
        >
          <img
            alt="LoveLocal"
            src={pinkLogo ? '/Assets/logo-pink.png' : '/Assets/logo.png'}
            width="100%"
            className={pinkLogo ? 'my-n7' : ''}
          />
        </a>
      </Col>
      <Col
        xs={16}
      >
        <div className="w-180p">
          {String.ADDRESS_LINE_1[language]}
          ,
        </div>
        <div className="w-180p">
          {String.ADDRESS_LINE_2[language]}
          ,
        </div>
        <div className="w-180p">
          {String.ADDRESS_LINE_3[language]}
        </div>
      </Col>
    </Row>

    <Row
      className="pt-3"
    >
      <Col xs={24}>
        <b
          className="text-gray"
        >
          {String.FOOTER_LOVELOCAL[language]}
        </b>
      </Col>
      <Col
        xs={24}
        className="pt-2 text-white"
      >
        <span>
          <Link
            to={{
              pathname: `/partner/${language}/about`,
              state: {
                scrollToTop: true,
              },
            }}
            className="text-white"
          >
            {String.ABOUT_US[language]}
            &nbsp;&nbsp;|
          </Link>
        </span>
        <span>
          <Link
            to={`/partner/${language}/form`}
            className="text-white"
          >
            &nbsp;&nbsp;
            {String.PARTNER_WITH_US[language]}
            &nbsp;&nbsp;|
          </Link>
        </span>
        <span>
          <a
            {...LoveLocal.contactUs}
            className="text-white"
          >
            &nbsp;&nbsp;
            {String.CONTACT_US[language]}
            &nbsp;&nbsp;|
          </a>
        </span>
        <span>
          <a
            {...LoveLocal.press}
            className="text-white"
          >
            &nbsp;&nbsp;
            Press
            : media@lovelocal.in&nbsp;&nbsp;|
          </a>
        </span>
        <span>
          <a
            {...LoveLocal.career}
            className="text-white"
          >
            &nbsp;&nbsp;
            {String.FOR_CAREERS[language]}
            : dream@lovelocal.in&nbsp;&nbsp;|
          </a>
        </span>
        <span>
          <a
            {...LoveLocal.partnership}
            className="text-white"
          >
            &nbsp;&nbsp;
            {String.FOR_PARTNERSHIP[language]}
            : partner@lovelocal.in
          </a>
        </span>
      </Col>
    </Row>

    <Row
      className="pt-3"
    >
      <Col
        xs={24}
      >
        <b
          className="text-gray"
        >
          {String.FOLLOW_US[language]}
        </b>
      </Col>
      <Col
        xs={24}
        className="pt-2 text-white"
      >
        <span>
          <a
            {...LoveLocal.facebook}
            className="text-white"
          >
            {String.FACEBOOK[language]}
            &nbsp;&nbsp;|
          </a>
        </span>
        <span>
          <a
            {...LoveLocal.twitter}
            className="text-white"
          >
            &nbsp;&nbsp;
            {String.TWITTER[language]}
            &nbsp;&nbsp;|
          </a>
        </span>
        <span>
          <a
            {...LoveLocal.insta}
            className="text-white"
          >
            &nbsp;&nbsp;
            {String.INSTAGRAM[language]}
            &nbsp;&nbsp;|
          </a>
        </span>
        <span>
          <a
            {...LoveLocal.linkedin}
            className="text-white"
          >
            &nbsp;&nbsp;
            {String.LINKEDIN[language]}
            &nbsp;&nbsp;|
          </a>
        </span>
        <span>
          <a
            {...LoveLocal.youtube}
            className="text-white"
          >
            &nbsp;&nbsp;
            {String.YOUTUBE[language]}
          </a>
        </span>
      </Col>
    </Row>

    <Row
      className="pt-3"
    >
      <Col
        xs={24}
      >
        <b
          className="text-gray"
        >
          {String.LEGAL[language]}
        </b>
      </Col>
      <Col
        xs={24}
        className="pt-2 text-white"
      >
        <span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LoveLocal?.privacyPolicy?.href}
            className="text-white"
          >
            {String.PRIVACY_POLICY[language]}
            &nbsp;&nbsp;|
          </a>
        </span>
        <span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={LoveLocal?.tnc?.href}
            className="text-white"
          >
            &nbsp;&nbsp;
            {String.TERMS_CONDITION[language]}
          </a>
        </span>
      </Col>
    </Row>
  </Container>
);

FooterMobile.propTypes = {
  pinkLogo: PropTypes.bool,
  language: PropTypes.string.isRequired,
};

FooterMobile.defaultProps = {
  pinkLogo: false,
};

export default FooterMobile;
